import { createMuiTheme } from "@material-ui/core";

const primaryColor = '#c0202c';

const defaultTheme = {
    typography: {
        h6: {
            fontWeight: 400,
            fontSize: '1.1rem'
        }
    },
    palette: {
        action: {
            hoverOpacity: 0.08
        },
        primary: {
            main: '#c0202c',
            contrastText: '#fff'
        },
        secondary: {
            main: '#006699',
            contrastText: '#fff'
        },
        background: {
            default: '#f0f0f0'
        },
        text: {
            primary: '#222'
        }
    },
    toolbar: {
        backgroundColor: 'rgba(255,255,255,0.6)'
    },
    sidebar: {
        defaultLogoForegroundColor: primaryColor,
        defaultLogoBackgroundColor: 'rgba(0,0,0,0)',
        background: `#ddd`,
        contrastText: '#222',
        buttonBackgroundColor: 'inherit',
        buttonColor: '#444',
        selectedButtonBackgroundColor: 'rgba(255,255,255,0.6)',
        selectedButtonColor: '#000'
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    background: "url(/background.png)"
                }
            }
        }
    }
};

export default createMuiTheme(defaultTheme);