import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, DialogContent, DialogActions, Button, Divider, Typography } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import * as Yup from 'yup';

import portalApi, { handleError } from '../../../utils/portalApi';
import ResponsiveDialog from '../../shared/ResponsiveDialog';

const FormContent = (props) => {
    const { errors, touched, values, handleChange, handleBlur } = useFormikContext();
    const languages = props.languages;

    return(
        <div>
            <div style={{ marginBottom: '2em' }}>   
                <Typography variant="h6" gutterBottom>Activity Title</Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    required
                    error={errors?.title && touched?.title}
                    helperText={errors?.title}
                    id="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur} />
            </div>

            <div style={{ marginBottom: '2em' }}>
                <Typography variant="h6" gutterBottom>Description</Typography>
                <TextField
                    variant="outlined"
                    style={{ width: '100%' }}
                    error={errors?.description && touched?.description}
                    helperText={errors?.description}
                    id="description"
                    value={values.description}
                    onChange={handleChange}
                    multiline
                    rows="3" />
                <Typography variant="caption">Only visible to Moderators/Observers</Typography>
            </div>

            <div style={{ marginBottom: '2em' }}>
                <Typography variant="h6" gutterBottom>Language</Typography>
                <Select
                    variant="outlined"
                    fullWidth
                    id="languageId"
                    name="languageId"
                    value={values.languageId}
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>Choose Language</em>
                    </MenuItem>
                    
                    { 
                        languages?.map((language) => 
                            <MenuItem key={language.id} value={language.id}>{language.displayName}</MenuItem>
                        )
                    }
                </Select>
            </div>
        </div>
    );
}

const FormActions = (props) => {
    const { handleSubmit, dirty, isSubmitting, isValid } = useFormikContext();

    return (
        <DialogActions>
            <Button size="large" onClick={props.onCancel}>
                Cancel
            </Button>
            <Button 
                variant="contained" 
                size="large"
                onClick={handleSubmit} 
                color="primary" 
                disabled={!isValid || isSubmitting || !dirty}>
                Save Changes
            </Button>
        </DialogActions>
    )
}

const EditBasicInfoModal = (props) => {
    const activityId = props.activity.details.activityId;
    const [languages, setLanguages] = useState(null);
    
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
        languageId: Yup.string().required()
    });

    useEffect(() => {
        portalApi
            .get(`/api/culture/languages`)
            .then(response => setLanguages(response.data))
            .catch(handleError);
    }, []);

    const handleSubmit = (values, actions) => {
        portalApi
            .put(`/api/activity/${activityId}/details`, {
                title: values.title,
                description: values.description,
                languageId: values.languageId
            })
            .then(response => {
                props.onSave();
                actions.setSubmitting(false);
            })
            .catch(handleError);
    };    

    return (
        <Formik
            initialValues={{
                title: props.activity.details.title,
                description: props.activity.details.description,
                languageId: props.activity.details.languageId
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <ResponsiveDialog heading={"Edit Basic Info."} open={props.open} onCancel={props.onCancel}>
                <DialogContent style={{ backgroundColor: '#f5f5f5', paddingTop: '2em', paddingBottom: '2em' }}>
                    <FormContent languages={languages} />
                </DialogContent>

                <Divider />              
                <FormActions onCancel={props.onCancel} />
            </ResponsiveDialog>
        </Formik>
    );
}

export default EditBasicInfoModal;
