import React, { useState } from 'react';
import SubjectIcon from '@material-ui/icons/Subject';
import EventIcon from '@material-ui/icons/Event';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import EditBasicInfoModal from './EditBasicInfoModal';
import EditScheduleModal from './EditScheduleModal';
import EditModeratorsModal from './EditModeratorsModal';
import EditObserversModal from './EditObserversModal';
import EditRespondentsModal from './EditRespondentsModal';
import SectionContainer from './SectionContainer';
import BasicInfo from './BasicInfo';
import ModeratorsInfo from './ModeratorsInfo';
import ObserversInfo from './ObserversInfo';
import RespondentsInfo from './RespondentsInfo';
import ScheduleInfo from './ScheduleInfo';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const SectionIcons = {
    details: <SubjectIcon />,
    schedule: <EventIcon />,
    moderators: <SupervisorAccountIcon />,
    observers: <SupervisorAccountIcon />,
    respondents: <SupervisorAccountIcon />
}

const SectionInfoComponents = {
    details: <BasicInfo />,
    schedule: <ScheduleInfo />,
    moderators: <ModeratorsInfo />,
    observers: <ObserversInfo />,
    respondents: <RespondentsInfo />
};

const SectionEditModalComponents = {
    details: <EditBasicInfoModal />,
    schedule: <EditScheduleModal />,
    moderators: <EditModeratorsModal />,
    observers: <EditObserversModal />,
    respondents: <EditRespondentsModal />
};

const SectionErrorSeverity = {
    todo: "error",
    warning: "warning"
};

const SetupActivitySection = (props) => {
    const { t } = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const title = t(`activitySetupSections.text.${props.section}`);

    if (props.validationStatus === null) {
        return null;
    }

    const errors = () => {
        const errors = props.validationStatus?.errors;
        const severity = SectionErrorSeverity[props.validationStatus?.validity];

        if (errors.length > 0) {
            return (
                <div>
                    { errors?.map(error => <Alert key={error} severity={severity}>{ error }</Alert>) }
                </div>
            )
        }

        return null;
    }

    const renderContent = () => {
        if (!SectionIcons[props.section] || !SectionInfoComponents[props.section] || !SectionEditModalComponents[props.section]) {
            return null;
        } else {
            return (
                <React.Fragment>
                    { React.cloneElement(SectionInfoComponents[props.section], {
                        activity: props.activity
                    }) }

                    { React.cloneElement(SectionEditModalComponents[props.section], {
                        activity: props.activity,
                        open: modalIsOpen,
                        onCancel: () => {
                            setModalIsOpen(false);
                        },
                        onSave: () => {
                            setModalIsOpen(false);
                            props.onSave();
                        }
                    }) }
                </React.Fragment>
            );
        }
    }

    return (
        <SectionContainer title={title} icon={SectionIcons[props.section]} onEditBtnClick={() => setModalIsOpen(true)}>
            { errors() }
            { renderContent() }
        </SectionContainer>
    );
}

export default SetupActivitySection;