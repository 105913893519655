import React, { useState } from 'react';
import { 
    Popover, 
    IconButton, 
    Switch, 
    List, 
    ListItem, 
    ListItemText, 
    ListItemSecondaryAction, 
    ListSubheader, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Tooltip, 
    Badge, 
    Button, 
    Collapse 
} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import { useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';

export const DEFAULT_LIST_SETTINGS = {
    searchText: "",
    shouldShowCompleted: false,
    sortBy: 'creationDate',
};

const ProjectListSettings = (props) => {
    const { t } = useTranslation();
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    
    const defaultSettingsAreActive = deepEqual(props.listSettings, DEFAULT_LIST_SETTINGS);
    const renderListSettingsIcon = () => {
        if (defaultSettingsAreActive) {
            return (<TuneIcon />)
        } else {
            return (
                <Badge color="secondary" variant='dot'>
                    <TuneIcon />
                </Badge>
            )
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={ t('common.text.listSettingsHeading') }>
                <IconButton onClick={e => setSettingsAnchorEl(e.currentTarget)}>
                    { renderListSettingsIcon() }
                </IconButton>
            </Tooltip>

            <Popover
                open={Boolean(settingsAnchorEl)}
                anchorEl={settingsAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onBackdropClick={e => setSettingsAnchorEl(null)}
                onEscapeKeyDown={e => setSettingsAnchorEl(null)}
            >
                <List style={{ minWidth: 300, width: 300 }}>
                    <ListSubheader>List Settings</ListSubheader>
                    <ListItem>
                        <ListItemText 
                            primary={ t('common.buttons.showCompletedEntities', { total: props.totalCompletedActivities }) } />
                        <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                checked={props.listSettings.shouldShowCompleted}
                                onChange={(e) => { 
                                    const currentVal = props.listSettings.shouldShowCompleted;
                                    props.onListSettingsChange({ ...props.listSettings, shouldShowCompleted: !currentVal })
                                }}
                                value="shouldShowCompleted"
                                color="primary"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="project-list-options-sort-by-label">{ t('common.labels.sortBy') }</InputLabel>
                            <Select
                                labelId="project-list-options-sort-by-label"
                                id="project-list-sort-by-dropdown"
                                value={props.listSettings.sortBy}
                                onChange={e => {
                                    props.onListSettingsChange({ ...props.listSettings, sortBy: e.target.value })
                                }}
                            >
                                <MenuItem value={'name'}>{ t('common.labels.entityName') }</MenuItem>
                                <MenuItem value={'creationDate'}>{ t('common.labels.createdDate') }</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    
                    <Collapse in={!defaultSettingsAreActive}>
                        <div style={{ padding: '1em' }}>
                            <Button 
                                fullWidth 
                                variant="outlined" 
                                onClick={e => props.onListSettingsChange(DEFAULT_LIST_SETTINGS)}>
                                { t('common.buttons.resetForm') }
                            </Button>
                        </div>
                    </Collapse>
                </List>
            </Popover>
        </React.Fragment>
    )
};

export default ProjectListSettings;
