import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const ModeratorsInfo = (props) => {
    const moderators = props.activity.moderators.available.filter(moderator => props.activity.moderators.participants.includes(moderator.id));

    const moderatorsTable = () => (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    moderators.map(moderator => {
                        return(
                            <TableRow key={moderator.id} hover>
                                <TableCell>{ `${moderator.firstName} ${moderator.lastName}` }</TableCell>
                                <TableCell>{ moderator.email }</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    );

    const errors = () => {
        const errors = props.validationStatus?.errors;
        return errors?.map(error => <Alert key={error} severity="error">{ error }</Alert>);
    }

    return (
        <div style={{ padding: '1.5em' }}>
            { errors() }
            { moderators.length > 0 && moderatorsTable() }
        </div>
    )
}

export default ModeratorsInfo;