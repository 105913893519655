// Convenience hooks for accessing the same bit of state from various components
// without duplicate code.
import { useSelector } from 'react-redux';

export const useUserDetails = () => {
    return useSelector(state => {
        if (state.UserDetailsReducer && state.UserDetailsReducer.userDetails) {
            return state.UserDetailsReducer.userDetails;
        } else {
            return null;
        }
    })
};

export const useSelectedCompany = () => {
    return useSelector(state => {
        if (state.SelectedCompanyReducer && state.SelectedCompanyReducer.selectedCompany) {
            return state.SelectedCompanyReducer.selectedCompany;
        } else {
            return null;
        }
    })
};

export const useSelectedProject = () => {
    return useSelector(state => {
        if (state.SelectedProjectReducer && state.SelectedProjectReducer.selectedProject) {
            return state.SelectedProjectReducer.selectedProject;
        } else {
            return null;
        }
    })
};

export const useSelectedActivity = () => {
    return useSelector(state => {
        if (state.SelectedActivityReducer && state.SelectedActivityReducer.selectedActivity) {
            return state.SelectedActivityReducer.selectedActivity;
        } else {
            return null;
        }
    })
};