import React from 'react';
import { Typography, Paper, IconButton } from '@material-ui/core';
import ToolbarSpacer from '../../shared/ToolbarSpacer';

import EditIcon from '@material-ui/icons/Edit';

const SectionContainer = (props) => {
    return (
        <Paper variant="outlined">
            <div style={{
                display: 'flex',
                alignItems: 'center',
                padding: '1.5em',
                paddingBottom: 0
            }}>
                { props.icon }
                <ToolbarSpacer />
                <Typography variant="h6" display="block">{ props.title }</Typography>
                
                <ToolbarSpacer grow />

                <IconButton onClick={props.onEditBtnClick} >
                    <EditIcon />
                </IconButton>
            </div>

            { props.children }
        </Paper>
    );
}

export default SectionContainer;
