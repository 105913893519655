import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

import SidebarContent from './SidebarContent';
import { setIsMenuOpen } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('xl')]: {
            width: theme.sidebar.desktopWidth || 340,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        backgroundColor: theme.sidebar.backgroundColor,
        background: theme.sidebar.background,
        [theme.breakpoints.up('lg')]: {
            width: theme.sidebar.desktopWidth || 340
        },
        border: theme.sidebar.border,
        color: theme.sidebar.contrastText
    }
}));

function Sidebar(props) {
    const classes = useStyles();
    const isMenuOpen = useSelector(state => state.UserInterfaceStateReducer.menu.isOpen);
    const dispatch = useDispatch();

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
                <Drawer
                    container={props.appContainer}
                    variant="temporary"
                    open={isMenuOpen}
                    onClose={() => { dispatch(setIsMenuOpen(false)) }}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <SidebarContent />
                </Drawer>
            </Hidden>
            <Hidden lgDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <SidebarContent />
                </Drawer>
            </Hidden>
        </nav>
    )
}

export default Sidebar;