import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

export const NoProjectsFound = (props) => {
    const { t } = useTranslation();

    return (
        <EmptyProjectsPlaceholder
            title={ t('companyPage.text.noProjectsFound') }
            message={ t('companyPage.text.noProjectsFoundMessage') }
            onCreateProjectButtonClick={props.onCreateProjectButtonClick}
        />
    )
}

export const NoActiveProjects = (props) => {
    const { t } = useTranslation();

    return (
        <EmptyProjectsPlaceholder
            title={ t('companyPage.text.noActiveProjects') }
            message={ t('companyPage.text.noActiveProjectsMessage') }
            onCreateProjectButtonClick={props.onCreateProjectButtonClick}
        />
    )
}

const EmptyProjectsPlaceholder = (props) => {
    const { t } = useTranslation();

    return (
        <Paper style={{ padding: '4em' }} variant="outlined">
            <div style={{ paddingBottom: '1.5em' }}>
                <Typography variant="h5" gutterBottom>{ props.title }</Typography>
                <Typography variant="body1">{ props.message }</Typography>
            </div>

            <Button 
                variant="contained"
                color="primary"
                onClick={props.onCreateProjectButtonClick}>
                { t('companyPage.buttons.createNewProjectVerbose') }
            </Button>
        </Paper>
    );
}

export default EmptyProjectsPlaceholder;