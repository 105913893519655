import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { useUserDetails } from '../../redux/hooks';
import BasicSnackbar from '../shared/BasicSnackbar';
import { apiUrl } from '../../config';
import { useTranslation } from 'react-i18next';

const UpdatePassword = () => {
    const { t } = useTranslation();
    const userDetails = useUserDetails();
    const [notification, setNotification] = useState(null);

    const handleFormSubmission = (formData, form) => {
        fetch(`${apiUrl}/api/currentuser/password`, {
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: userDetails.email,
                password: formData.currentPassword,
                newPassword: formData.newPassword
            })
        }).then(response => {
            if (response.ok) {
                setNotification({
                    key: "passwordUpdated",
                    message: t("userAccountSettingsPage.text.passwordUpdatedSuccessfully")
                });

                form.resetForm();
            } else {
                throw response;
            }
        }).catch(response => {
            if (response.status === 403) {
                response.json().then(json => {
                    setNotification({
                        key: "passwordUpdateFailed",
                        message: t('userAccountSettingsPage.errors.unableToChangePassword_currentPasswordIncorrect')
                    })
                });
            } else {
                setNotification({
                    key: "passwordUpdateFailed",
                    message: t('userAccountSettingsPage.errors.unableToChangePassword_unknownError')
                })
            }
        }).finally(() => {
            form.setSubmitting(false);
        });
    };

    function equalTo(ref, msg) {
        return Yup.mixed().test({
            name: 'equalTo',
            exclusive: false,
            message: msg,
            params: {
                reference: ref.path,
            },
            test: function (value) {
                return value === this.resolve(ref);
            },
        });
    }
    Yup.addMethod(Yup.string, 'equalTo', equalTo);

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required(),
        newPassword: Yup.string().required(),
        confirmNewPassword: Yup.string().equalTo(Yup.ref('newPassword'), t('userAccountSettingsPage.errors.passwordsMustMatch')).required()
    });

    const renderForm = () => {
        const initialValues = {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmission}
                validationSchema={validationSchema} >

                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                        handleReset
                    } = props;

                    return (
                        <form autoComplete="off">
                            <TextField
                                fullWidth
                                margin='normal'
                                type="password"
                                id="currentPassword"
                                label={ t('userAccountSettingsPage.labels.currentPassword') }
                                value={values.currentPassword}
                                onChange={handleChange}
                                error={errors.currentPassword && touched.currentPassword}
                                helperText={touched.currentPassword ? errors.currentPassword : null} />

                            <br />

                            <TextField
                                fullWidth
                                margin='normal'
                                type="password"
                                id="newPassword"
                                label={ t('userAccountSettingsPage.labels.newPassword') }
                                value={values.newPassword}
                                onChange={handleChange}
                                error={errors.newPassword && touched.newPassword}
                                helperText={touched.newPassword ? errors.newPassword : null} />

                            <br />

                            <TextField
                                fullWidth
                                margin='normal'
                                type="password"
                                id="confirmNewPassword"
                                label={ t('userAccountSettingsPage.labels.confirmNewPassword') }
                                value={values.confirmNewPassword}
                                onChange={handleChange}
                                error={errors.confirmNewPassword && touched.confirmNewPassword}
                                helperText={touched.confirmNewPassword ? errors.confirmNewPassword : null} />

                            <br /><br />

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isSubmitting || !dirty}>{ t('userAccountSettingsPage.buttons.changePassword') }</Button>

              &nbsp;

                            <Fade in={dirty}>
                                <Button onClick={handleReset}>{ t('common.buttons.resetForm') }</Button>
                            </Fade>
                        </form>
                    );
                }}
            </Formik>
        );
    }

    return (
        <div>
            <BasicSnackbar
                notification={notification}
                onClose={() => setNotification(null)}
            />

            {renderForm()}
        </div>
    );
}

export default UpdatePassword;