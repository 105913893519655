import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TopBar from '../shared/TopBar';
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Paper from '@material-ui/core/Paper';
import PortalIFrame from '../shared/PortalIFrame';

const AboutHome = () => {
    const { t } = useTranslation();
    
    const [privacyPolicyIsOpen, setPrivacyPolicyIsOpen] = useState(false);
    const [termsAndConditionsIsOpen, setTermsAndConditionsIsOpen] = useState(false);

    return (
        <React.Fragment>
            <TopBar pageTitle={ t('aboutPage.text.pageTitle') } />

            <Container>
                <Typography variant='h6' gutterBottom>App Information</Typography>
                <Paper style={{ padding: '1em', marginBottom: '1em' }} variant="outlined">
                    <Typography variant="overline" gutterBottom>VisionsLive Portal - Version { process.env.REACT_APP_VERSION }</Typography>
                    
                    <ul>
                        <li><Typography variant="body2">{ t('aboutPage.text.copyright') }</Typography></li>
                        <li><Typography variant="body2">{ t('aboutPage.text.contactTelephone') }</Typography></li>
                        <li><Typography variant="body2">{ t('aboutPage.text.contactTelephoneUSA') }</Typography></li>
                    </ul>
                </Paper>

                <Typography variant='h6' gutterBottom>Further Reading</Typography>

                <Paper style={{ padding: '1em', marginBottom: '1em' }} variant="outlined">
                    <Button onClick={() => setPrivacyPolicyIsOpen(true)}>{ t('aboutPage.buttons.openPrivacyPolicy')}</Button>
                    <Button onClick={() => setTermsAndConditionsIsOpen(true)}>{ t('aboutPage.buttons.openTermsAndConditions')}</Button>
                </Paper>
            </Container>

            <Dialog 
                maxWidth='lg'
                open={privacyPolicyIsOpen} 
                onClose={() => setPrivacyPolicyIsOpen(false)}>
                <DialogContent>
                    <PortalIFrame 
                        relativeUrl={"/Home/PrivacyStatement"}
                        containerProps={{ disableGutters: true }}
                        paperProps={{ variant: "outlined" }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPrivacyPolicyIsOpen(false)}>{ t('common.buttons.close' )}</Button>
                </DialogActions>
            </Dialog>
            
            <Dialog 
                maxWidth='lg'
                open={termsAndConditionsIsOpen} 
                onClose={() => setTermsAndConditionsIsOpen(false)}>
                <DialogContent>
                    <PortalIFrame 
                        relativeUrl={"/Home/TermsAndConditions"}
                        containerProps={{ disableGutters: true }}
                        paperProps={{ variant: "outlined" }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTermsAndConditionsIsOpen(false)}>{ t('common.buttons.close' )}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default AboutHome;