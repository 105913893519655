import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Internationalisation
import './i18n';

// Date/Timepickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

// Application State (Redux)
import { Provider } from 'react-redux';
import PortalStore from './redux/store';
import { CircularProgress } from '@material-ui/core';

const Loading = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f5f5f5'
        }}>
            <CircularProgress style={{ color: '#c20202' }}/>
        </div>
    )
}

// Unregister service workers (no further SWs will be registered at this time due to
// a couple of issues).
if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for(let registration of registrations) {
            registration.unregister()
        }
    });
}

ReactDOM.render(
    <Suspense fallback={<Loading />}>
        <Provider store={PortalStore}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <App />
            </MuiPickersUtilsProvider>
        </Provider>
    </Suspense>, document.getElementById('root'));
