import React from 'react';
import { Button, Typography } from '@material-ui/core';

import portalApi, { handleError } from '../../../utils/portalApi';

const activityIsValid = (validationStatus) => {
    if (!validationStatus) {
        return false;
    }

    const invalidSection = Object.keys(validationStatus).find(key => {
        return validationStatus[key]?.validity === "todo";
    });

    return invalidSection === undefined;
}

const PublishActivityPanel = (props) => {
    const { activity, validationStatus } = props;  
    const isPublished = activity.details.status === 'published';
    const isValid = activityIsValid(validationStatus);

    const publishActivity = () => {
        let source = portalApi.CancelToken.source();
    
        // Execute getCompany/Project/Activity requests at the same time to avoid
        // race condition. 
        portalApi
            .post(`/api/activity/${activity.details.activityId}/publish`)
            .then(response => {
                alert(response);
            })
            .catch(handleError);
    
        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    };

    const renderPublishBtn = () => (
        <Button 
            variant="contained" 
            color="primary"
            disabled={!isValid} 
            fullWidth
            onClick={publishActivity}>
            Publish Activity
        </Button>
    );

    return (
        <React.Fragment> 
            { isPublished 
                ? <Typography variant="body1">This activity has been published</Typography>
                : renderPublishBtn() }

            { isValid === false && 
                <Typography variant="caption" style={{ display: 'inline-block', paddingTop: '1em' }}>
                    Validation issues are preventing this activity from being published
                </Typography>
            }
        </React.Fragment>
    )
}

export default PublishActivityPanel;
