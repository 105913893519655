import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import portalApi, { handleError } from '../../utils/portalApi';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import { useSelectedCompany } from '../../redux/hooks';
import { Button, DialogActions, DialogContent, Divider, FormControl, InputAdornment, InputLabel, NativeSelect, TextField } from '@material-ui/core';
import ResponsiveDialog from '../shared/ResponsiveDialog';

const CreateProjectForm = (props) => {
    const { t } = useTranslation();
    const { values, handleChange, touched, errors } = useFormikContext();

    const { languages, emailIsEditable, themes, emailSuffix } = props;

    return (
        <form autoComplete="off">
            <TextField
                required
                fullWidth
                margin='normal'
                id="projectName"
                label={ t('createProjectForm.labels.projectName') }
                value={values.projectName}
                onChange={handleChange}
                error={errors.projectName && touched.projectName}
                helperText={touched.projectName ? errors.projectName : null} />

            <TextField
                fullWidth
                margin='normal'
                required
                error={errors.clientName && touched.clientName}
                helperText={touched.clientName ? errors.clientName : null}
                id="clientName"
                label={ t('createProjectForm.labels.projectClientName') }
                value={values.clientName}
                onChange={handleChange} />

            <TextField
                fullWidth
                margin='normal'
                id="projectDesc"
                label={ t('createProjectForm.labels.projectDescription') }
                value={values.projectDesc}
                onChange={handleChange}
                error={errors.projectDesc && touched.projectDesc}
                helperText={touched.projectDesc ? errors.projectDesc : null} />

            <FormControl margin='normal' fullWidth error={errors.language && touched.language}>
                <InputLabel htmlFor="language-placeholder">{ t('createProjectForm.labels.defaultLanguage') }</InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'Default Language',
                        id: 'language-placeholder'
                    }}
                    onChange={event => {
                        event.target.name = 'language';
                        handleChange(event);
                    }}
                    id="language"
                    label={ t('createProjectForm.labels.defaultLanguage') }
                    value={values.language}
                    error={errors.language && touched.language}>
                    {languages.map((language) => <option key={language.id} value={language.id}>{language.displayName}</option>)}
                </NativeSelect>
            </FormControl>

            <FormControl margin='normal' fullWidth error={errors.theme && touched.theme}>
                <InputLabel htmlFor="theme-placeholder">{ t('createProjectForm.labels.projectTheme') }</InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'theme',
                        id: 'theme-placeholder'
                    }}
                    onChange={event => {
                        event.target.name = 'theme';
                        handleChange(event);
                    }}
                    id="theme"
                    label={ t('createProjectForm.labels.projectTheme') }
                    value={values.theme}
                    error={errors.theme && touched.theme}>
                    {themes.map((theme) => <option key={theme.id} value={theme.id}>{theme.name}</option>)}
                </NativeSelect>
            </FormControl>

            <TextField
                InputProps={
                    emailIsEditable ? {
                        endAdornment: <InputAdornment position="end">{emailSuffix}</InputAdornment>,
                    }: null
                }
                disabled={!emailIsEditable}
                margin='normal'
                id="email"
                label={ t('createProjectForm.labels.senderEmailAddress') }
                value={values.email}
                onChange={handleChange}
                error={errors.email && touched.email}
                helperText={touched.email ? errors.email : null} />
        </form>
    )
};

const CreateProjectActions = (props) => {
    const { t } = useTranslation();
    const { handleSubmit, isSubmitting, dirty } = useFormikContext();

    return (
        <DialogActions>
            <Button
                size="large"
                onClick={props.onCancel}>{ t('common.buttons.cancel') }</Button>

            <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting || !dirty}>{ t('createProjectForm.buttons.createProject') }</Button>
        </DialogActions>
    )
};

const CreateProjectModal = (props) => {
    const { t } = useTranslation();
    const [languages, setLanguages] = useState(null);
    const [themes, setThemes] = useState(null);
    const [companyEmail, setCompanyEmail] = useState(null);
    const selectedCompany = useSelectedCompany();

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        portalApi
            .get('/api/culture/languages')
            .then(response => response.data.sort((a, b) => {
                if (a.displayName === b.displayName) {
                    return 0;
                }

                return a.displayName < b.displayName ? -1 : 1;
            }))
            .then(data => setLanguages(data))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, []);

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        portalApi
            .get(`/api/companies/${selectedCompany.id}/themes`)
            .then(response => setThemes(response.data))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [selectedCompany.id]);

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        portalApi
            .get(`/api/companies/${selectedCompany.id}/email`)
            .then(response => setCompanyEmail(response.data))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [selectedCompany.id]);

    const handleFormSubmission = (formData, form) => {
        const email = companyEmail.isEditable ? `${formData.email}${companyEmail.value}`: null;

        portalApi
            .post('/api/projects', {
                companyId: selectedCompany.id,
                project: {
                    client: formData.clientName,
                    name: formData.projectName,
                    description: formData.projectDesc,
                    languageId: formData.language,
                    themeId: formData.theme,
                    emailAddress: {
                        name: email
                    }
                }
            })
            .then(() => {
                // setNotification({ key: "Success", message: "Project added successfully." });
                form.resetForm();
                props.onProjectCreated();
            })
            .catch(handleError)
            .finally(() => form.setSubmitting(false));
    };

    const validationSchema = Yup.object().shape({
        clientName: Yup.string().required(),
        projectName: Yup.string().required(),
        projectDesc: Yup.string(),
        language: Yup.string().required(),
        theme: Yup.string().required(),
        email: Yup.string().required().transform((val, originalVal) => {
            return companyEmail.isEditable ? `${val}${companyEmail.value}` : val;
        }).email()
    });

    const renderLoading = () => {
        if (!languages || !themes || !companyEmail) {
            return (
                <LoadingPlaceholder height={'auto'} />
            )
        }

        return null;
    };

    const renderForm = () => {
        return (
            <CreateProjectForm
                languages={languages}
                themes={themes}
                emailSuffix={`@${companyEmail.value}`}
                emailIsEditable={companyEmail.isEditable} />
        );
    }

    const initialValues = {
        clientName: "",
        projectName: "",
        projectDesc: "",
        email: companyEmail?.isEditable ? "" : companyEmail?.displayValue,
        theme: selectedCompany.defaultProductTheme.id,
        language: "f1b12460-e842-11e2-91e2-0800200c9a66" // Default to English.
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleFormSubmission}
            validationSchema={validationSchema} >

            <ResponsiveDialog
                heading={t('companyPage.buttons.createNewProject')}
                open={props.open}
                onCancel={props.onCancel}>
                <DialogContent style={{ width: '35em', maxWidth: '100%' }}>
                    { renderLoading() || renderForm() }
                </DialogContent>

                <Divider />

                <CreateProjectActions onCancel={props.onCancel} />
            </ResponsiveDialog>
        </Formik>
    )
}

export default CreateProjectModal;