import React from 'react';
import { DialogContent, DialogActions, Button, Divider, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import portalApi, { handleError } from '../../../utils/portalApi';
import ResponsiveDialog from '../../shared/ResponsiveDialog';

const FormContent = (props) => {
    const { values, initialValues, setFieldValue } = useFormikContext();
    const availableObserverIds = props.availableObservers.map(observer => observer.id);

    const handleToggleAll = () => {
        if (values.selectedObserverIds.length === 0) {
            // Nothing currently selected, so just select all available observers.
            setFieldValue('selectedObserverIds', availableObserverIds);
        } else if(values.selectedObserverIds.length !== props.availableObservers.length) {
            setFieldValue('selectedObserverIds', [...new Set([...values.selectedObserverIds, ...availableObserverIds])]);
        } else {
            setFieldValue('selectedObserverIds', []);
        }
    }

    const handleObserverToggle = (observer) => {
        if (values.selectedObserverIds.includes(observer.id)) {
            setFieldValue('selectedObserverIds', values.selectedObserverIds.filter(val => val !== observer.id));
        } else {
            setFieldValue('selectedObserverIds', [observer.id, ...values.selectedObserverIds]);
        }
    };

    const getObserverSelectionStatus = (observer) => {
        if (initialValues.selectedObserverIds.includes(observer.id) && !values.selectedObserverIds.includes(observer.id)) {
            return -1;
        }

        if (values.selectedObserverIds.includes(observer.id) && !initialValues.selectedObserverIds.includes(observer.id)) {
            return 1;
        }

        return 0;
    }

    const getObserverSelectionStatusIcon = (observer) => {
        switch (getObserverSelectionStatus(observer)) {
        case -1: 
            return (
                <Tooltip arrow title="This user will be removed">
                    <Avatar variant="rounded" style={{ backgroundColor: 'red', width: '1em', height: '1em' }}>
                        <RemoveIcon style={{ width: '90%', height: 'auto' }} />
                    </Avatar>
                </Tooltip>
            );
        case 1:
            return (
                <Tooltip arrow title="This user will be added">
                    <Avatar variant="rounded" style={{ backgroundColor: 'green', width: '1em', height: '1em' }}>
                        <AddIcon style={{ width: '90%', height: 'auto' }} />
                    </Avatar>
                </Tooltip>
            )
        default:
            return null;
        }
    }

    const pseudonymColumnIsVisible = props.availableObservers.find(observer => observer.pseudonym);
    const timezoneColumnIsVisible = props.availableObservers.find(observer => observer.timezone);

    return(
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                edge="start"
                                checked={values.selectedObserverIds.length === props.availableObservers.length}
                                tabIndex={-1}
                                disableRipple
                                color="primary"
                                onClick={handleToggleAll}
                            />
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        { pseudonymColumnIsVisible && <TableCell>Pseudonym</TableCell> }
                        { timezoneColumnIsVisible && <TableCell>Timezone</TableCell> }
                        <TableCell style={{ minWidth: '4em' }}>&nbsp; { /* Change indicator icon */ } </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.availableObservers.map(observer => {
                            return(
                                <TableRow 
                                    key={observer.id} 
                                    selected={values.selectedObserverIds.includes(observer.id)}
                                    onClick={() => handleObserverToggle(observer) }
                                    hover
                                    style={{ cursor: "pointer" }}>
                                    <TableCell>
                                        <Checkbox
                                            edge="start"
                                            checked={values.selectedObserverIds.includes(observer.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                            
                                        />
                                    </TableCell>
                                    <TableCell>{ `${observer.firstName} ${observer.lastName}` }</TableCell>
                                    <TableCell>{ observer.email }</TableCell>
                                    { pseudonymColumnIsVisible && <TableCell>{ observer.pseudonym }</TableCell> }
                                    { timezoneColumnIsVisible && <TableCell>{ observer.timezone }</TableCell> }
                                    <TableCell>{ getObserverSelectionStatusIcon(observer) }</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

const FormActions = (props) => {
    const { handleSubmit, dirty, isSubmitting } = useFormikContext();

    return (
        <DialogActions>
            <Button size="large" onClick={props.onCancel}>
                Cancel
            </Button>
            <Button 
                variant="contained" 
                size="large"
                onClick={handleSubmit} 
                color="primary" 
                disabled={isSubmitting || !dirty}>
                Save Changes
            </Button>
        </DialogActions>
    )
}

const EditObserversModal = (props) => {
    const activityId = props.activity.details.activityId;
    const availableObservers = props.activity.observers.available;

    const handleSubmit = (values, actions) => {       
        portalApi
            .put(`/api/activity/${activityId}/observers`, {
                available: [],
                participants: values.selectedObserverIds
            })
            .then(response => {
                props.onSave();
                actions.setSubmitting(false);
            })
            .catch(handleError);
    };

    return (
        <Formik
            initialValues={{
                selectedObserverIds: props.activity.observers.participants
            }}
            onSubmit={handleSubmit}
        >
            <ResponsiveDialog heading={"Select Observers"} open={props.open} onCancel={props.onCancel}>
                <DialogContent style={{ backgroundColor: '#f5f5f5', paddingTop: '1em', paddingBottom: '2em' }}>
                    <FormContent availableObservers={availableObservers} />
                </DialogContent>

                <Divider />              
                <FormActions onCancel={props.onCancel} />
            </ResponsiveDialog>
        </Formik>
    );
}

export default EditObserversModal;
