import React, { useState, useEffect } from 'react';
import { useUserDetails } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import CardHeader from '@material-ui/core/CardHeader';

import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import BasicAccountSettings from './BasicAccountSettings';
import UpdateEmailAddress from './UpdateEmailAddress';
import UpdatePassword from './UpdatePassword';
import portalApi, { handleError } from '../../utils/portalApi';
import TopBar from '../shared/TopBar';
import TimezoneSettings from '../shared/LocalisableDate/TimezoneSettings';
import { DateTime } from 'luxon';
import { FlexHeightContainer, FlexHeightInnerContainer } from '../shared/LayoutComponents';

const AccountSettings = () => {
    const { t } = useTranslation();
    const userDetails = useUserDetails();
    const [countries, setCountries] = useState(null);
    const [timeZones, setTimeZones] = useState(null);

    useEffect(() => {
        portalApi
            .get(`/api/culture/countries`)
            .then(response => response.data.sort((a, b) => {
                if (a.englishName === b.englishName) {
                    return 0;
                }

                return (a.englishName > b.englishName) ? 1 : -1;
            }))
            .then(json => setCountries(json))
            .catch(handleError);
    }, [])

    useEffect(() => {
        portalApi
            .get(`/api/culture/timezones`)
            .then(response => setTimeZones(response.data))
            .catch(handleError);
    }, [])

    const loading = () => {
        if (!countries || !timeZones) {
            return (
                <LoadingPlaceholder />
            )
        }
    }

    const renderBasicDetailsForm = () => {
        const initialValues = {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            phoneNumber: userDetails.phoneNumber,
            country: userDetails.country,
            timeZone: userDetails.timeZone,
            currentPassword: ""
        };

        return (
            <BasicAccountSettings
                initialValues={initialValues}
                countries={countries}
                timeZones={timeZones}
            />
        );
    }

    const renderUpdateEmailAddressForm = () => {
        const initialValues = {
            email: userDetails.email,
            currentPassword: ""
        }

        return (
            <UpdateEmailAddress
                initialValues={initialValues}
            />
        );
    };

    const renderContent = () => {
        return (
            <FlexHeightContainer>
                <TopBar pageTitle={ t('userAccountSettingsPage.text.pageTitle') } />
                <FlexHeightInnerContainer>
                    <Container>
                        <Card>
                            <CardHeader title={ t('userAccountSettingsPage.text.basicInfoHeading') } />
                            <CardContent>
                                {renderBasicDetailsForm()}
                            </CardContent>
                        </Card>

                        <br /><br />

                        <Card>
                            <CardHeader title={ t('userAccountSettingsPage.text.changeEmailHeading') } />
                            <CardContent>
                                {renderUpdateEmailAddressForm()}
                            </CardContent>
                        </Card>

                        <br /><br />

                        <Card>
                            <CardHeader title={ t('userAccountSettingsPage.text.changePasswordHeading') } />
                            <CardContent>
                                <UpdatePassword />
                            </CardContent>
                        </Card>

                        <br /><br />

                        <Card>
                            <CardHeader title={ t('timezoneSettings.text.changeYourTimezoneHeading') } />
                            <CardContent>
                                <TimezoneSettings
                                    date={DateTime.local().toISO()}
                                    titleIsHidden={true} />
                            </CardContent>
                        </Card>
                    </Container>
                </FlexHeightInnerContainer>
            </FlexHeightContainer>
        );
    }

    return (
        loading() || renderContent()
    );
};

export default AccountSettings;