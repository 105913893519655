import React, { useCallback, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';

import ProductCard from './ProductCard';
import HatchBackground from './productCards/hatch.jpg';
import VideoDialBackground from './productCards/videodial.jpg';
import HeatmapBackground from './productCards/heatmap.jpg';
import ProductType from '../../enums/ProductType';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import FocusGroupProductCard from './FocusGroupProductCard';
import IDIProductCard from './IDIProductCard';

const getProducts = (t) => ([
    {
        productType: ProductType.heatmapConceptTest,
        name: t('productTypes.text.heatmapConceptTest'),
        description: t('productDescriptions.text.heatmapConceptTest'),
        background: HeatmapBackground,
        iconColor: '#c9812b',
        portalURL: `/Heatmap/Create`
    },
    {
        productType: ProductType.videodialConceptTest,
        name: t('productTypes.text.videodialConceptTest'),
        description: t('productDescriptions.text.videodialConceptTest'),
        background: VideoDialBackground,
        iconColor: '#465C79',
        portalURL: `/Videodial/Create`
    },
    {
        productType: ProductType.brainstorm,
        name: t('productTypes.text.brainstorm'),
        description: t('productDescriptions.text.brainstorm'),
        background: HatchBackground,
        iconColor: '#126EC4',
        portalURL: `/Brainstorm/Create`
    }
    // Commenting-out to prevent creation of boards for now.
    // Awaiting completion of boards-integration tasks.
    // {
    //     productType: ProductType.bulletinBoard,
    //     name: t('productTypes.text.bulletinBoard'),
    //     description: t('productDescriptions.text.bulletinBoard'),
    //     background: FocusGroupBackground,
    //     iconColor: '#8BC34A',
    //     portalURL: `/Brainstorm/Create`
    // }
]);

const ActivityTypeSelector = (props) => {
    const { t } = useTranslation();

    const products = useMemo(() => getProducts(t), [t]);

    const { onSelect } = props;

    const handleSelectProduct = useCallback((product) => {
        onSelect(product);
    }, [onSelect]);

    return (
        <React.Fragment>
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    key="animation"
                    initial={{ opacity: 0, scaleY: 0 }}
                    animate={{ opacity: 1, scaleY: 1 }}
                    exit={{ opacity: 0, scaleY: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <Grid container spacing={3}>
                        <Grid item key={'focus-group-card'} xs={12} sm={6} md={4} lg={3}>
                            <FocusGroupProductCard onClick={handleSelectProduct} />
                        </Grid>

                        <Grid item key={'idi-card'} xs={12} sm={6} md={4} lg={3}>
                            <IDIProductCard onClick={handleSelectProduct} />
                        </Grid>

                        { products.map(product =>
                            <Grid item key={product.productType} xs={12} sm={6} md={4} lg={3}>
                                <ProductCard {...product} onClick={handleSelectProduct}  />
                            </Grid>
                        )}
                    </Grid>
                </motion.div>
            </AnimatePresence>
        </React.Fragment>
    )
}

export default ActivityTypeSelector;