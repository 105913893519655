import React, { useState, useCallback } from 'react';
import { DateTime } from 'luxon';
import { Typography, Divider, IconButton, Collapse, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import LocalisedTime from './LocalisedTime';
import TimezoneSettings from './TimezoneSettings';
import { useDispatch, useSelector } from 'react-redux';
import { addPreviewTimezone, removePreviewTimezone } from '../../../redux/actions';
import { MemoisedTimezonePicker } from './TimezonePicker';
import { useTranslation } from 'react-i18next';

const TimezonePreviewer = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isModifyingTimezoneSettings, setIsModifyingTimezoneSettings] = useState(false);
    const [isAddingTimezone, setIsAddingTimezone] = useState(false);

    const referenceTimezone = props.referenceDate.zoneName;
    const localTimezone = DateTime.local().zoneName;
    const additionalZones = useSelector(state => state.UserInterfaceStateReducer.previewTimezones);

    const onTimezoneChanged = useCallback((timezone) => {
        setIsModifyingTimezoneSettings(false);
    }, []);

    const onTimezoneAdded = useCallback((timezone) => {
        setIsAddingTimezone(false);
        dispatch(addPreviewTimezone(timezone));
    }, [dispatch]);

    const timezonesDiffer = () => {
        return referenceTimezone !== localTimezone;
    }

    const editButton = () => {
        if (!timezonesDiffer()) {
            return null;
        }

        return (
            <IconButton onClick={() => setIsModifyingTimezoneSettings(true)}>
                <HelpIcon />
            </IconButton>
        );
    }

    return (
        <div style={{ padding: '1em', width: '28rem', maxWidth: '100%' }}>                   
            { !isModifyingTimezoneSettings &&
                <div>
                    <Typography variant="caption" display="block" gutterBottom>
                        { t('timezonePreviewer.labels.yourTimezone') }
                    </Typography>
                    
                    <LocalisedTime
                        date={props.referenceDate.toISO()} 
                        timezone={props.referenceDate.zoneName}
                        secondaryAction={
                            editButton()
                        } />

                    <Divider style={{ marginTop: '1em', marginBottom: '1em' }}/>

                    <Typography variant="caption" display="block" gutterBottom>
                        { t('timezonePreviewer.labels.otherTimezones') }
                    </Typography>

                    { additionalZones.map((timezone, index) => (
                        <LocalisedTime 
                            key={timezone}
                            date={props.referenceDate.toISO()} 
                            timezone={timezone}
                            referenceDate={props.referenceDate}
                            typographyVariant={'body1'}
                            secondaryAction={
                                <IconButton onClick={() => dispatch(removePreviewTimezone(timezone))}>
                                    <DeleteIcon />
                                </IconButton>
                            } />
                    )) }

                    { additionalZones.length > 0 && 
                        <Divider style={{ marginTop: '1em', marginBottom: '1em' }}/>
                    }

                    { !isAddingTimezone && 
                        <Button 
                            startIcon={<AddIcon />}
                            onClick={() => setIsAddingTimezone(true)}>{ t('timezonePreviewer.buttons.addTimezonePreview') }</Button>
                    }
                </div>
            }

            <Collapse in={isModifyingTimezoneSettings}>
                <TimezoneSettings 
                    date={props.referenceDate.toISO()}
                    onTimezoneChanged={onTimezoneChanged} />
            </Collapse>

            { isModifyingTimezoneSettings && 
                <React.Fragment>
                    <Divider style={{ marginTop: '1em', marginBottom: '1em' }}/>
                    <Button fullWidth onClick={() => setIsModifyingTimezoneSettings(false)}>
                        { t('common.buttons.cancel') }
                    </Button>
                </React.Fragment>
            }

            <Collapse in={isAddingTimezone}>
                <MemoisedTimezonePicker onSelect={onTimezoneAdded} />
            </Collapse>
        </div>
    )
}

export default TimezonePreviewer;