import React, { useEffect, useMemo } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';

import ActivitiesIcon from '@material-ui/icons/Palette';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import ReportsIcon from '@material-ui/icons/Description';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { setSelectedActivity, setSelectedCompany, setSelectedProject } from '../../redux/actions';
import { useSelectedProject } from '../../redux/hooks';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import portalApi, { handleError } from '../../utils/portalApi';
import TopBar from '../shared/TopBar';
import TopBarTabs from '../shared/TopBarTabs';
import PortalIFrame from '../shared/PortalIFrame';
import Reports from './Reports';
import CompanyRole from '../../enums/CompanyRole';
import ActivitiesTab from './activities/ActivitiesTab';
import OverviewTab from './OverviewTab';
import { FlexHeightContainer, FlexHeightInnerContainer } from '../shared/LayoutComponents';

const getTabs = (t, companyRole) => ([
    {
        key: 'overview',
        label: t('projectPage.tabs.projectOverview'),
        icon: <DashboardIcon />,
        enabled: companyRole?.canDeleteProject
    },
    {
        key: 'activities',
        label: t('projectPage.tabs.manageActivities'),
        icon: <ActivitiesIcon />,
        enabled: true
    },
    {
        key: 'users',
        label: t('projectPage.tabs.manageParticipants'),
        icon: <GroupIcon />,
        enabled: companyRole?.canManageProjectUsers
    },
    {
        key: 'reports',
        label: t('projectPage.tabs.viewReports'),
        icon: <ReportsIcon />,
        enabled: true
    },
    {
        key: 'settings',
        label: t('projectPage.tabs.projectSettings'),
        icon: <SettingsIcon />,
        enabled: true
    }
]);

const ProjectOverview = ({ match }) => {
    const history = useHistory();
    const companyId = match.params.company_guid;
    const projectId = match.params.project_guid;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const selectedCompany = useSelector(state => state.SelectedCompanyReducer?.selectedCompany);
    const companyRole = CompanyRole[selectedCompany?.role];
    const selectedProject = useSelectedProject();

    const pageRoot = `/company/${match.params.company_guid}/project/${match.params.project_guid}`

    const tabs = useMemo(() => getTabs(t, companyRole), [t, companyRole]).filter(tab => tab.enabled);

    useEffect(() => {
        dispatch(setSelectedActivity(null));
    }, [dispatch]);

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        const getCompany = portalApi.get(`/api/companies/${companyId}`);
        const getProject = portalApi.get(`/api/projects/${projectId}`);

        // Execute getCompany/getProject requests at the same time to avoid
        // race condition.
        Axios
            .all([getCompany, getProject])
            .then(Axios.spread((...responses) => {
                dispatch(setSelectedCompany(responses[0].data));
                dispatch(setSelectedProject(responses[1].data));
            }))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [companyId, projectId, dispatch]);

    const routes = () => {
        return (
            <React.Fragment>
                <Route path={`${pageRoot}/overview`}>
                    <FlexHeightInnerContainer>
                        <OverviewTab companyId={companyId} companyRole={companyRole} projectId={projectId} />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={`${pageRoot}/activities`} exact>
                    <FlexHeightInnerContainer disablePadding>
                        <ActivitiesTab companyId={companyId} projectId={projectId} />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={`${pageRoot}/users`}>
                    <FlexHeightInnerContainer>
                        <PortalIFrame
                            id={'projectUsersIframe'}
                            relativeUrl={`/xx/${projectId}/Respondent`}
                            redirectUrl={`${pageRoot}/activities`}
                        />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={`${pageRoot}/reports`}>
                    <FlexHeightInnerContainer>
                        <Reports
                            projectId={projectId}
                            redirectUrl={`${pageRoot}/activities`}
                        />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={`${pageRoot}/settings`}>
                    <FlexHeightInnerContainer>
                        <PortalIFrame
                            id={'projectSettingsIframe'}
                            relativeUrl={`/xx/${projectId}/Project/Edit`}
                            redirectUrl={`${pageRoot}/overview`}
                        />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={pageRoot} exact>
                    <Redirect from={pageRoot} to={`${pageRoot}/overview`} exact />
                </Route>
            </React.Fragment>
        )
    }

    const loading = () => (
        selectedProject ? null : <LoadingPlaceholder />
    )

    const main = () => (
        <FlexHeightContainer disablePadding>
            <TopBar
                breadcrumbs={[
                    {
                        link: `/company/${selectedCompany.id}`,
                        title: selectedCompany.name
                    },
                    {
                        title: selectedProject.name
                    }
                ]}
            >
                <Container>
                    <TopBarTabs
                        value={Math.max(tabs.findIndex((tab) => tab.key === match.params.tab), 0)}
                        onChange={(event, newValue) => {
                            history.push(`${pageRoot}/${tabs[newValue].key}`);
                        }}
                        variant="scrollable"
                    >
                        { tabs.map(tab =>
                            <Tab key={tab.key} label={tab.label} />
                        )}
                    </TopBarTabs>
                </Container>
            </TopBar>

            { routes() }
        </FlexHeightContainer>
    )

    return (
        loading() || main()
    );
}

export default ProjectOverview;