import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const ObserversInfo = (props) => {
    const observers = props.activity.observers.available.filter(observer => props.activity.observers.participants.includes(observer.id));

    const observersTable = () => (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    observers.map(observer => {
                        return(
                            <TableRow key={observer.id} hover>
                                <TableCell>{ `${observer.firstName} ${observer.lastName}` }</TableCell>
                                <TableCell>{ observer.email }</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    );

    return (
        observers.length > 0 && observersTable()
    )
}

export default ObserversInfo;