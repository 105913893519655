import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch} from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { listItemStyles, selectedListItemStyles } from './SidebarListItemStyles';

const SelectActivitySection = (props) => {
    const { activity } = props;
    const { t } = useTranslation();

    const routeIsActive = useRouteMatch({
        path: '/company/:company_guid/project/:project_guid/activity/:activity_guid/:tab?',
        strict: true,
        exact: true
    });

    const classes = routeIsActive ? selectedListItemStyles() : listItemStyles();

    return (
        <React.Fragment>
            <ListItem button
                className={classes.listItem}
                onClick={props.onClick}
            >
                <ListItemText
                    primary={t('sidebar.labels.selectedActivity')}
                    primaryTypographyProps={{ className: classes.primaryText }}
                    secondary={activity.details.description}
                    secondaryTypographyProps={{ className: classes.secondaryText }}
                />
            </ListItem>
        </React.Fragment>
    );
}

export default SelectActivitySection;
