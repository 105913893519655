// For use when selecting from a list using the up/down/enter keys instead of the mouse.
export const handleKeyDown = (e, items, selectedItemIndex, setSelectedItemIndex, selectItem) => {
    if (e.key === "ArrowDown") {
        if (selectedItemIndex === null) {
            setSelectedItemIndex(0);
        } else if(selectedItemIndex + 1 < items.length) {
            setSelectedItemIndex(selectedItemIndex + 1);
        }
        
        e.preventDefault();
    }

    if (e.key === "ArrowUp") {
        if (selectedItemIndex === 0) {
            setSelectedItemIndex(null);
        } else {
            setSelectedItemIndex(selectedItemIndex - 1);
        }

        e.preventDefault();
    }

    if (e.key === "Enter") {
        if (selectedItemIndex === null) {
            if (items.length === 1) {
                selectItem(items[0]);
            } else {
                setSelectedItemIndex(0);
            }
        } else {
            selectItem(items[selectedItemIndex]);
        }

        e.preventDefault();
    }
};