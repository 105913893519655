export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const SET_SELECTED_ACTIVITY = 'SET_SELECTED_ACTIVITY';
export const SET_IS_MENU_OPEN = 'SET_IS_MENU_OPEN';
export const SET_WAITING_SERVICE_WORKER = 'SET_WAITING_SERVICE_WORKER';
export const SET_IS_OFFLINE = 'SET_IS_OFFLINE';
export const SET_THEME = 'SET_THEME';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const ADD_PREVIEW_TIMEZONE = 'ADD_PREVIEW_TIMEZONE';
export const REMOVE_PREVIEW_TIMEZONE = 'REMOVE_PREVIEW_TIMEZONE';
export const SET_USER_TIMEZONE = "SET_USER_TIMEZONE";
export const SET_COMPANY_COUNT = "SET_COMPANY_COUNT";

export function setUserDetails(userDetails) {
    return { type: SET_USER_DETAILS, userDetails }
}

export function setUserTimezone(timezone) {
    return { type: SET_USER_TIMEZONE, timezone }
}

export function setSelectedCompany(company) {
    return { type: SET_SELECTED_COMPANY, company }
}

export function setCompanyCount(companyCount) {
    return { type: SET_COMPANY_COUNT, companyCount }
}

export function setSelectedProject(project) {
    return { type: SET_SELECTED_PROJECT, project }
}

export function setSelectedActivity(activity) {
    return { type: SET_SELECTED_ACTIVITY, activity }
}

export function setIsMenuOpen(isOpen) {
    return { type: SET_IS_MENU_OPEN, isOpen }
}

export function setWaitingServiceWorker(serviceWorker) {
    return { type: SET_WAITING_SERVICE_WORKER, serviceWorker }
}

export function setIsOffline(isOffline) {
    return { type: SET_IS_OFFLINE, isOffline }
}

export function setTheme(theme) {
    return { type: SET_THEME, theme }
}

export function setTimezone(timezone) {
    return { type: SET_TIMEZONE, timezone }
}

export function addPreviewTimezone(timezone) {
    return { type: ADD_PREVIEW_TIMEZONE, timezone }
}

export function removePreviewTimezone(timezone) {
    return { type: REMOVE_PREVIEW_TIMEZONE, timezone }
}
