import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import portalApi, { handleError } from '../../utils/portalApi';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import TableView from './TableView';
import TopBar from '../shared/TopBar';
import SearchBox from '../shared/SearchBox';
import ToolbarSpacer from '../shared/ToolbarSpacer';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { FlexHeightContainer, FlexHeightInnerContainer, ToolbarContainer } from '../shared/LayoutComponents';
import { setCompanyCount, setSelectedActivity, setSelectedCompany, setSelectedProject } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    limiterOutputContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    }
}));

const companyNameSorter = (a, b) => {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

const companyLastAccessedDateSorter = (a, b) => {
    if (!a.lastAccessed && !b.lastAccessed) {
        return 0;
    }

    if ((a.lastAccessed > b.lastAccessed) || (a.lastAccessed && !b.lastAccessed)) {
        return -1;
    }

    if ((a.lastAccessed < b.lastAccessed) || (!a.lastAccessed && b.lastAccessed)) {
        return 1;
    }

    return 0;
}

export default function () {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const LIMITED_LIST_SIZE = 10;
    const history = useHistory();
    const classes = useStyles();
    const [companies, setCompanies] = React.useState(null);
    const [filteredCompanies, setFilteredCompanies] = React.useState(null);
    const [selectedCompanyIndex, setSelectedCompanyIndex] = React.useState(-1);
    const [isLimiterActive, setIsLimiterActive] = React.useState(true);

    const selectCompany = useCallback(async (company) => {
        // Log the selection
        portalApi
            .post(`/api/companies/${company.id}/logSelection`)
            .then(() => {
                history.push(`/company/${company.id}`);
            })
            .catch(handleError);
    }, [history]);

    useEffect(() => {
        // on mount
        dispatch(setSelectedCompany(null));
        dispatch(setSelectedProject(null));
        dispatch(setSelectedActivity(null));
    }, [dispatch])

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        const getCompany = portalApi.get(`/api/companies`);
        const getCompanySelections = portalApi.get(`/api/companies/latestSelections`);

        // Execute getCompany/Project/Activity requests at the same time to avoid
        // race condition.
        Axios
            .all([getCompany, getCompanySelections])
            .then(Axios.spread((...responses) => {
                var companiesArray = responses[0].data;
                var selectionsArray = responses[1].data;

                dispatch(setCompanyCount(companiesArray.length));

                if (companiesArray.length === 1) {
                    selectCompany(companiesArray[0])
                } else {
                    // Convert companies to object so we can get more efficient
                    // lookup.
                    var companiesHash = Object.fromEntries(
                        companiesArray.map(i => [i.id, i])
                    )

                    // Loop through recent company selections (top 5 for now) and populate
                    // the relevant company with the timestamp at which it was last accessed.
                    selectionsArray.slice(0, 5).forEach(selection => {
                        if (companiesHash[selection.companyId]) {
                            companiesHash[selection.companyId].lastAccessed = selection.timestamp;
                        }
                    });

                    // Set companies as normal, sorting recently accessed to the top of the list and then
                    // alphabetically.
                    setCompanies(
                        Object.values(companiesHash).sort(companyNameSorter).sort(companyLastAccessedDateSorter)
                    );
                }
            }))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [selectCompany, dispatch]);

    const updateFilteredCompanies = (searchText) => {
        let results;

        if (searchText.length === 0) {
            results = null;
        } else {
            const beginsWith = companies
                .filter((company) => {
                    return company.name.toUpperCase().indexOf(searchText.toUpperCase()) === 0
                })
                .sort(companyNameSorter)
                .sort(companyLastAccessedDateSorter);

            const contains = companies
                .filter((company) => {
                    return company.name.toUpperCase().indexOf(searchText.toUpperCase()) > 0;
                })
                .sort(companyNameSorter)
                .sort(companyLastAccessedDateSorter);

            results = [...beginsWith, ...contains];
        }

        if (results && results.length < LIMITED_LIST_SIZE) {
            setIsLimiterActive(false);
        }

        setFilteredCompanies(results);
    };

    const getCompanies = useCallback((options) => {
        const results = filteredCompanies || companies;

        if (isLimiterActive && (options === undefined || options.overrideLimiter === false)) {
            return results.slice(0, LIMITED_LIST_SIZE);
        } else {
            return results;
        }
    }, [companies, filteredCompanies, isLimiterActive]);

    function gotoPrevElement() {
        if (selectedCompanyIndex >=0) {
            setSelectedCompanyIndex(selectedCompanyIndex - 1);
        }
    }

    function gotoNextElement() {
        if (selectedCompanyIndex < getCompanies().length - 1) {
            setSelectedCompanyIndex(selectedCompanyIndex + 1);
        } else {
            setIsLimiterActive(false);
        }
    }

    function searchKeyDown(e) {
        if (e.key === "ArrowDown") {
            gotoNextElement();
            e.preventDefault();
        }
        if (e.key === "ArrowUp") {
            gotoPrevElement();
            e.preventDefault();
        }
        if (e.key === "Enter") {
            var focusedCompany = getCompanies()[selectedCompanyIndex];
            if (focusedCompany) {
                selectCompany(focusedCompany);
            } else {
                // If there isn't already a company selected, then either select
                // or highlight the first result.
                if (getCompanies().length === 1) {
                    selectCompany(getCompanies()[0]);
                } else if(getCompanies().length > 0) {
                    gotoNextElement();
                }
            }
            e.preventDefault();
        }
    }

    const renderLoading = () => {
        if (!companies) {
            return (
                <LoadingPlaceholder />
            )
        }

        return null;
    }

    const renderResultsTable = () => (
        <React.Fragment>
            <TableView
                companies={getCompanies()}
                handleSelection={selectCompany}
                selectedIndex={selectedCompanyIndex} />

            { isLimiterActive &&
                <div className={classes.limiterOutputContainer}>
                    <Button variant="contained" color="secondary" onClick={() => { setIsLimiterActive(false) }}>
                        { t('common.buttons.showAllEntities', { total: getCompanies({ overrideLimiter: true }).length}) }
                    </Button>
                </div>
            }
        </React.Fragment>
    );

    const renderSelectionUI = () => (
        <FlexHeightContainer onKeyDown={searchKeyDown}>
            <TopBar breadcrumbs={[{ title: t('selectCompanyPage.text.pageTitle') }]} />

            <ToolbarContainer>
                <Container>
                    <Toolbar disableGutters>
                        <SearchBox
                            onChange={(e) => {
                                setSelectedCompanyIndex(-1);
                                setIsLimiterActive(true);
                                updateFilteredCompanies(e.target.value);
                            }} />
                        <ToolbarSpacer grow />
                    </Toolbar>
                </Container>
            </ToolbarContainer>

            <FlexHeightInnerContainer>
                <Container>
                    { getCompanies().length > 0 ? renderResultsTable() : <Typography variant="h5">{ t('common.text.noSearchResultsFound') }</Typography> }
                </Container>
            </FlexHeightInnerContainer>
        </FlexHeightContainer>
    )

    return (
        renderLoading() || renderSelectionUI()
    )
}