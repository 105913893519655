import React from 'react';
import { useDispatch } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

// Icons
import MenuIcon from '@material-ui/icons/Menu';

// Hooks
import UserMenu from './Menus/UserMenu';
import { setIsMenuOpen } from '../../redux/actions';
import { Breadcrumbs, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import ToolbarSpacer from './ToolbarSpacer';

const useStyles = makeStyles(theme => ({
    avatar: {
        margin: 10,
        marginRight: 0
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: theme.palette.divider
    }
}));

function TopBar(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);

    const handleUserMenuButtonClick = (event) => {
        setUserMenuAnchorEl(event.target);
    };

    const handleCloseUserMenu = () => {
        setUserMenuAnchorEl(null);
    };

    const renderBreadcrumbs = () => {
        if (props.breadcrumbs) {
            return props.breadcrumbs.map(crumb => {
                if (crumb.link) {
                    return (
                        <Link key={`crumb-${crumb.title}`} href="#" onClick={() => history.push(crumb.link)} color="primary">
                            <Typography variant="subtitle2">{ crumb.title }</Typography>
                        </Link>
                    )
                } else {
                    return <Typography key={`crumb-${crumb.title}`} variant="subtitle2" color="inherit">{ crumb.title }</Typography>;
                }
            });
        }
    };

    return (
        <React.Fragment>
            <AppBar position='sticky' className={classes.appBar} elevation={0}>
                <Container>
                    <Toolbar disableGutters>
                        <Hidden xlUp>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={() => { dispatch(setIsMenuOpen(true)) }} >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>

                        <ToolbarSpacer />

                        <Breadcrumbs color="inherit">
                            { renderBreadcrumbs() }
                        </Breadcrumbs>

                        <div className={classes.grow} />

                        <UserAvatar style={{ cursor: 'pointer' }} onClick={handleUserMenuButtonClick} />
                    </Toolbar>
                </Container>

                { props.children }
            </AppBar>

            <UserMenu
                anchorEl={userMenuAnchorEl}
                onClose={handleCloseUserMenu} />
        </React.Fragment>
    )
}

export default TopBar;