import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { CardActionArea } from '@material-ui/core';

import ActivityIcon from '../shared/activityIcon/ActivityIcon';
import ProductType from '../../enums/ProductType';
import BackgroundImage from './productCards/idi.jpg';
import { useCardStyles } from './UseCardStyles';
import AVProductCardActions from './AVProductCardActions';

const IDIProductCard = (props) => {
    const { t } = useTranslation();
    const classes = useCardStyles({ color: '#E69700' });

    return (
        <Card className={classes.card}>
            <CardActionArea disabled={true} className={classes.cardActionArea}>
                <Avatar className={classes.iconAvatar}>
                    <ActivityIcon productType={ProductType.inDepthInterviewV2} />
                </Avatar>

                <CardMedia
                    image={BackgroundImage}
                    title={t('productTypes.text.inDepthInterview')}
                    className={classes.media} />

                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        { t('productTypes.text.inDepthInterview') }
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        { t('productDescriptions.text.inDepthInterview') }
                    </Typography>
                </CardContent>
            </CardActionArea>

            <AVProductCardActions
                productType={ProductType.inDepthInterviewV2}
                avProductType={ProductType.inDepthInterviewV2Av}
                onClick={props.onClick} />
        </Card>
    )
};

export default React.memo(IDIProductCard);
