import React from 'react';
import { Typography } from '@material-ui/core';
import ToolbarSpacer from '../ToolbarSpacer';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const LocalisedTime = (props) => {
    const { i18n } = useTranslation();
    const selectedTimezone = useSelector(state => state.UserDetailsReducer?.ianaTimezoneName);
    const typographyVariant = props.typographyVariant ?? 'h6';
    
    const date = DateTime
        .fromISO(props.date, { 
            locale: props.language ?? i18n.language 
        })
        .setZone(props.timezone ?? selectedTimezone ?? 'local');

    // This can be necessary if the time spans across a day boundary.
    const showDate = () => {
        if (!props.referenceDate) {
            return false;
        }

        const referenceDate = props.referenceDate.toLocaleString({ month: 'short', day: '2-digit' });
        const thisDate = date.toLocaleString({ month: 'short', day: '2-digit' });

        return referenceDate !== thisDate;
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flexShrink: 0 }}>
                <Typography variant={typographyVariant} display="block">
                    { date.toLocaleString({ hour: 'numeric', minute: 'numeric', hour12: false }) }
                </Typography>

                { showDate() && 
                    <Typography variant='caption' display="block" align="right">
                        { date.toLocaleString({ month: 'short', day: '2-digit' }) }
                    </Typography>
                }
            </span>
            
            <ToolbarSpacer large />

            <Typography variant={typographyVariant} display="block">
                { date.toFormat('ZZZZZ') }
            </Typography>

            <ToolbarSpacer grow />

            { props.secondaryAction }
        </div>
    )
}

export default LocalisedTime;