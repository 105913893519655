import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ActivityTableRow from './ActivityTableRow';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    }
});

const ActivitiesTableView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleSelection = (activity) => {
        props.handleSelection(activity);
    };

    return (
        <Paper className={classes.root} variant="outlined">
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography variant="inherit">{ t(`activityStatuses.text.${props.status}`) }</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.activities.map((activity) => (
                            <ActivityTableRow
                                key={activity.activityId}
                                activity={activity}
                                onClick={() => handleSelection(activity) }
                                selected={props.selectedItemIndex === activity.activityId} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ActivitiesTableView;