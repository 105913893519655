import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import deepEqual from 'deep-equal';

import Container from '@material-ui/core/Container';

import { NoActiveProjects, NoProjectsFound } from './EmptyProjectsPlaceholder';
import portalApi, { handleError } from '../../../utils/portalApi';
import LoadingPlaceholder from '../../shared/LoadingPlaceholder';
import ProjectsTableView from './TableView';
import Toolbar from './Toolbar';
import { handleKeyDown } from '../../../utils/keyboardShortcutHelper';
import { DEFAULT_LIST_SETTINGS } from './ProjectListSettings';
import { FlexHeightInnerContainer, ToolbarContainer } from '../../shared/LayoutComponents';
import CreateProjectModal from '../CreateProjectModal';

const loadProjects = (companyId, setAllProjects) => {
    let source = portalApi.CancelToken.source();

    portalApi
        .get(`/api/projects?companyID=${companyId}`, {
            cancelToken: source.token
        })
        .then(response => {
            setAllProjects(response.data)
        })
        .catch(handleError);
}

const ProjectsTab = (props) => {
    const history = useHistory();

    const [allProjects, setAllProjects] = useState(null);
    const [listSettings, setListSettings] = useState(DEFAULT_LIST_SETTINGS);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [isCreatingProject, setIsCreatingProject] = useState(false);

    const companyId = props.companyId;
    const onProjectsLoaded = props.onProjectsLoaded;

    const byName = (a, b) => {
        if (a.name.toLowerCase() === b.name.toLowerCase()) {
            return 0;
        }

        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }

    const byCreationDate = (a, b) => {
        if (a.createdDate === b.createdDate) {
            return 0;
        }

        return a.createdDate < b.createdDate ? 1 : -1;
    }

    const bySearchTerm = (project) => {
        return project.name.toLowerCase().indexOf(listSettings.searchText.toLowerCase()) >= 0
    }

    const getActiveProjects = () => {
        return allProjects
            .filter(bySearchTerm)
            .filter(project => project.isArchived === false);
    };

    const getCompletedProjects = () => {
        return allProjects
            .filter(bySearchTerm)
            .filter(project => project.isArchived === true);
    };

    const getProjects = () => {
        var projects = listSettings.shouldShowCompleted ?
            [...getActiveProjects(), ...getCompletedProjects()] : getActiveProjects();

        if (listSettings.sortBy === 'creationDate') {
            return projects.sort(byCreationDate);
        } else {
            return projects.sort(byName);
        }
    }

    const onProjectSelected = useCallback(project => {
        // Redirect to /activities for now until we get a 'recent activities' component
        // in the Project Overview.
        history.push(`project/${project.id}/activities`);
    }, [history]);

    const renderProjects = () => {
        if (!allProjects) {
            return (<LoadingPlaceholder height={'auto'} />);
        } else if (getProjects().length === 0) {
            return deepEqual(listSettings, DEFAULT_LIST_SETTINGS)
                ? <NoActiveProjects onCreateProjectButtonClick={onCreateProjectBtnClick} />
                : <NoProjectsFound onCreateProjectButtonClick={onCreateProjectBtnClick} />
        } else {
            return (
                <ProjectsTableView
                    projects={getProjects()}
                    onProjectSelected={onProjectSelected}
                    selectedItemIndex={selectedItemIndex} />
            );
        }
    }

    const onCreateProjectBtnClick = () => {
        setIsCreatingProject(true);
    };

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        loadProjects(companyId, setAllProjects);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [companyId, onProjectsLoaded]);

    return(
        <FlexHeightInnerContainer disablePadding>
            <ToolbarContainer>
                <Container>
                    <Toolbar
                        totalCompletedActivities={allProjects ? getCompletedProjects().length : 0}
                        onCreateProjectButtonClick={onCreateProjectBtnClick}
                        searchBoxProps={{
                            onKeyDown: (e) => {
                                handleKeyDown(e, getProjects(), selectedItemIndex, setSelectedItemIndex, onProjectSelected);
                            }
                        }}
                        listSettings={listSettings}
                        onListSettingsChange={(settings) => setListSettings(settings)}
                    />
                </Container>
            </ToolbarContainer>

            <FlexHeightInnerContainer>
                <Container>
                    { renderProjects() }
                </Container>
            </FlexHeightInnerContainer>

            <CreateProjectModal
                open={isCreatingProject}
                onCancel={() => setIsCreatingProject(false)}
                onProjectCreated={() => {
                    setIsCreatingProject(false);
                    loadProjects(companyId, setAllProjects);
                }} />
        </FlexHeightInnerContainer>
    )
};

export default ProjectsTab;