import React from 'react';
import { useTranslation } from 'react-i18next';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ChatIcon from '@material-ui/icons/Chat';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { Divider } from '@material-ui/core';

const AVProductCardActions = (props) => {
    const { t } = useTranslation();
    const productType = props.productType;
    const avProductType = props.avProductType;

    return (
        <React.Fragment>
            <Divider />
            <CardActions>
                <Button
                    startIcon={<VideocamOutlinedIcon style={{ fill: 'red' }} />}
                    onClick={() => {
                        props.onClick({
                            productType: avProductType
                        })
                    }}>{ t('activityTypeSelector.buttons.videoChat') }</Button>

                <Button
                    startIcon={<ChatIcon style={{ fill: 'green'}} />}
                    onClick={() => {
                        props.onClick({
                            productType: productType
                        })
                    }}>{ t('activityTypeSelector.buttons.textChat') }</Button>
            </CardActions>
        </React.Fragment>
    )
};

export default React.memo(AVProductCardActions);
