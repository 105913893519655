import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AddIcon from '@material-ui/icons/Add';

import SearchBox from '../../shared/SearchBox';
import ToolbarSpacer from '../../shared/ToolbarSpacer';
import CompanyRole from '../../../enums/CompanyRole';

const useStyles = makeStyles(theme => ({
    fab: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    createBtn: {
        [theme.breakpoints.up('sm')]: {
            display: 'inline-flex'
        },
        display: 'none'
    },
    hidden: {
        display: 'none'
    }
}));

const ActivitiesToolbar = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState("");
    const [shouldShowCompleted, setShouldShowCompleted] = useState(false);
    const selectedCompany = useSelector(state => state.SelectedCompanyReducer?.selectedCompany);
    const companyRole = CompanyRole[selectedCompany.role];

    return (
        <React.Fragment>
            <Toolbar disableGutters>
                <SearchBox
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                        props.onSearchChange(e.target.value);
                    }}
                    onKeyDown={props.searchBoxProps?.onKeyDown} />

                <ToolbarSpacer large />

                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={shouldShowCompleted}
                                onChange={(e) => {
                                    setShouldShowCompleted(!shouldShowCompleted)
                                    props.onShouldShowCompletedActivitiesChange(!shouldShowCompleted)
                                }}
                                value="shouldShowCompletedActivities"
                                color="primary"
                            />
                        }
                        label={ t('common.buttons.showCompletedEntities', { total: props.totalCompletedActivities }) }
                    />
                </FormGroup>

                <ToolbarSpacer grow />

                { companyRole.canCreateActivity &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        className={classes.createBtn}
                        onClick={props.onCreateActivityButtonClick}>
                        { t('projectPage.buttons.createNewActivity') }
                    </Button>
                }
            </Toolbar>

            { companyRole.canCreateActivity &&
                <Fab color="primary"
                    aria-label="add"
                    className={classes.fab}
                    onClick={props.onCreateActivityButtonClick}>
                    <AddIcon />
                </Fab>
            }
        </React.Fragment>
    )
}

export default ActivitiesToolbar;