import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { setUserDetails } from '../../redux/actions';
import BasicSnackbar from '../shared/BasicSnackbar';
import portalApi, { handleError } from '../../utils/portalApi';
import { useTranslation } from 'react-i18next';

const BasicAccountSettings = (props) => {
    const { t } = useTranslation();
    const { countries } = props;
    const dispatch = useDispatch();
    const [notification, setNotification] = useState(null);

    const handleFormSubmission = (formData, form) => {
        portalApi
            .put('/api/currentuser/details', { 
                ...formData, 
                timezone: props.initialValues.timeZone
            })
            .then(response => {
                portalApi
                    .get('/api/currentuser/details')
                    .then(response => dispatch(setUserDetails(response.data)))
            })
            .then(() => {
                setNotification({ key: "Success", message: t('userAccountSettingsPage.text.userDetailsUpdatedSuccessfully') })
                form.setSubmitting(false);
            })
            .catch(handleError);
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        phoneNumber: Yup.string().max(20).required(),
        country: Yup.string().required(),
    });

    const renderForm = () => {
        return (
            <Formik
                enableReinitialize
                initialValues={props.initialValues}
                onSubmit={handleFormSubmission}
                validationSchema={validationSchema} >

                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                        handleReset
                    } = props;

                    return (
                        <form autoComplete="off">
                            <TextField
                                fullWidth
                                margin='normal'
                                required
                                error={errors.firstName && touched.firstName}
                                helperText={errors.firstName}
                                id="firstName"
                                label={ t('userAccountSettingsPage.labels.firstName') }
                                value={values.firstName}
                                onChange={handleChange} />

                            <br />

                            <TextField
                                fullWidth
                                margin='normal'
                                id="lastName"
                                label={ t('userAccountSettingsPage.labels.lastName') }
                                value={values.lastName}
                                onChange={handleChange}
                                error={errors.lastName && touched.lastName}
                                helperText={errors.lastName} />

                            <br />

                            <TextField
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                                }}
                                fullWidth
                                margin='normal'
                                id="phoneNumber"
                                label={ t('userAccountSettingsPage.labels.mobileNumber') }
                                value={values.phoneNumber}
                                onChange={handleChange}
                                error={errors.phoneNumber && touched.phoneNumber}
                                helperText={errors.phoneNumber} />

                            <FormControl margin='normal' fullWidth error={errors.country && touched.country}>
                                <InputLabel htmlFor="country-placeholder">{ t('userAccountSettingsPage.labels.country') }</InputLabel>
                                <NativeSelect
                                    inputProps={{
                                        name: 'Country',
                                        id: 'country-placeholder'
                                    }}
                                    onChange={event => {
                                        event.target.name = 'country';
                                        handleChange(event);
                                    }}
                                    id="country"
                                    label={ t('userAccountSettingsPage.labels.country') }
                                    value={values.country}
                                    error={errors.country && touched.country}>
                                    {countries.map((country) => <option key={country.id} value={country.id}>{country.englishName}</option>)}
                                </NativeSelect>
                            </FormControl>

                            <br /><br />

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isSubmitting || !dirty}>{ t('userAccountSettingsPage.buttons.updateUserDetails') }</Button>

              &nbsp;

                            <Fade in={dirty}>
                                <Button onClick={handleReset}>{ t('common.buttons.resetForm') }</Button>
                            </Fade>
                        </form>
                    );
                }}
            </Formik>
        );
    }

    return (
        <div>
            {renderForm()}

            <BasicSnackbar
                notification={notification}
                onClose={() => setNotification(null)}
            />
        </div>
    )
}

export default BasicAccountSettings;