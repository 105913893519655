import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import ActivityIcon from '../shared/activityIcon/ActivityIcon';
import { useCardStyles } from './UseCardStyles';

const ProductCard = (props) => {
    const { productType, name, description, background, iconColor, onClick } = props;
    const classes = useCardStyles({ color: iconColor });

    return (
        <Card className={classes.card}>
            <CardActionArea
                className={classes.cardActionArea}
                onClick={() => {
                    onClick(props);
                }}>

                <Avatar className={classes.iconAvatar}>
                    <ActivityIcon productType={productType} />
                </Avatar>

                <CardMedia
                    image={background}
                    title={name}
                    className={classes.media} />

                <CardContent style={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="h2">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        { description }
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
};

export default React.memo(ProductCard);
