// A themed TableRow component adding bold text and 'pointer' cursor
// to the row if selected or hovered respectively.
import React, { useRef } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { useEffect } from 'react';

const ThemedTableRow = withStyles(theme => ({
    root: {
        cursor: 'pointer',
        "&$selected": {
            '& .MuiTableCell-root': {
                fontWeight: 'bold'
            }
        }
    },
    selected: {},
    hover: {}
}))(TableRow);

const SelectableTableRow = (props) => {
    const domRef = useRef();
    const main = document.getElementsByTagName('main')[0];

    useEffect(() => {
        if(props.selected) {
            domRef.current.scrollIntoView({ block: 'nearest' });

            // Compensate for top bar
            if (domRef.current.offsetTop < main.scrollTop) {
                main.scroll(0, domRef.current.offsetTop - 60); // -60 (compensate for table header)
            }
        }
    }, [props.selected, domRef, main]);

    return (
        <ThemedTableRow 
            ref={domRef}
            key={props.id} 
            hover={true} 
            onClick={() => props.onClick(props.company)}
            selected={props.selected} 
        >
            { props.children }
        </ThemedTableRow>
    )
};

export default SelectableTableRow;