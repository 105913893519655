import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, DialogContent, Typography, withStyles } from '@material-ui/core';
import ReportsIcon from '@material-ui/icons/Description';

import SelectableTableRow from '../../shared/SelectableTableRow';
import ActivityAvatar from '../../shared/ActivityAvatar';
import LocalisableDate from '../../shared/LocalisableDate/LocalisableDate';
import ResponsiveDialog from '../../shared/ResponsiveDialog';
import { createReportsRelativeURL } from '../../activity/activityUrlHelper';
import ProductType from '../../../enums/ProductType';
import PortalIFrame from '../../shared/PortalIFrame';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    }
});

const ViewReportButton = withStyles(theme => ({
    root: {
        color: '#196de2',
        backgroundColor: '#e8f0fc',
        '&:hover': {
            borderColor: '#196de2',
            backgroundColor: '#D1E1F9'
        },
        textTransform: 'none'
    }
}))(Button);

const CompletedActivityTableRow = (props) => {
    const { t } = useTranslation();
    const { activity } = props;

    return (
        <SelectableTableRow key={activity.activityId} hover={true} onClick={props.onClick} selected={props.selected}>
            <TableCell>
                <ActivityAvatar
                    productType={activity.productType}
                    status={activity.status} />
            </TableCell>
            <TableCell component="th" scope="row" style={{ width: '100%' }}>
                <Typography variant="body2">{activity.name}</Typography>
            </TableCell>
            <TableCell>
                <LocalisableDate date={activity.startDate} typographyProps={{ variant: 'inherit' }} />
            </TableCell>
            <TableCell>
                <ViewReportButton
                    variant="outlined"
                    startIcon={<ReportsIcon />}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onViewReportClick()
                    }}>
                    <Typography variant="inherit" noWrap>{ t('activityPage.buttons.viewReport') }</Typography>
                </ViewReportButton>
            </TableCell>
        </SelectableTableRow>
    )
};

const byScheduledStartDateDesc = (a, b) => {
    if (a.startDate === b.startDate) {
        return 0;
    }

    return a.startDate < b.startDate ? 1 : -1;
}

const CompletedActivitiesTableView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [reportViewSettings, setReportViewSettings] = useState(null);

    const handleSelection = (activity) => {
        props.handleSelection(activity);
    }

    return (
        <Paper className={classes.root} variant="outlined">
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Typography variant="subtitle2">{ t(`activityStatuses.text.completed`) }</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.activities.sort(byScheduledStartDateDesc).map((activity, index) => (
                            <CompletedActivityTableRow
                                key={activity.activityId}
                                activity={activity}
                                onClick={() => handleSelection(activity) }
                                onViewReportClick={() => {
                                    setReportViewSettings({
                                        activityName: activity.name,
                                        reportUrl: createReportsRelativeURL(ProductType[activity.productType], props.projectId, activity.activityId)
                                    })
                                }}
                                selected={props.selectedItemIndex === activity.activityId} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ResponsiveDialog heading={reportViewSettings?.activityName} open={Boolean(reportViewSettings)} onCancel={() => setReportViewSettings(null)}>
                <DialogContent>
                    <PortalIFrame
                        relativeUrl={reportViewSettings?.reportUrl}
                        containerProps={{ disableGutters: true }}
                        paperProps={{ elevation: 0, style: { padding: 0 }}} />
                </DialogContent>
            </ResponsiveDialog>
        </Paper>
    );
};

export default CompletedActivitiesTableView;