import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';

import SearchBox from '../../shared/SearchBox';
import ToolbarSpacer from '../../shared/ToolbarSpacer';
import { useSelector } from 'react-redux';
import CompanyRole from '../../../enums/CompanyRole';
import ProjectListSettings from './ProjectListSettings';

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    createBtn: {
        marginLeft: theme.spacing(1)
    }
}));

const ProjectsToolbar = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const selectedCompany = useSelector(state => state.SelectedCompanyReducer?.selectedCompany)
    const companyRole = CompanyRole[selectedCompany.role];

    return (
        <React.Fragment>
            <Toolbar disableGutters>
                <SearchBox 
                    value={props.listSettings.searchText} 
                    onChange={(e) => { 
                        props.onListSettingsChange({ ...props.listSettings, searchText: e.target.value });
                    }} 
                    onKeyDown={props.searchBoxProps?.onKeyDown}
                />

                <ToolbarSpacer />

                <ProjectListSettings 
                    listSettings={props.listSettings}
                    onListSettingsChange={props.onListSettingsChange}
                    totalCompletedActivities={props.totalCompletedActivities}
                    sortBy={props.sortBy}
                    onSortByChange={props.onSortByChange}
                />

                <ToolbarSpacer grow />

                { companyRole.canCreateProject &&
                    <Hidden xsDown>
                        <Tooltip title={ t('companyPage.tooltips.createNewProject') } arrow>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                startIcon={<AddIcon />} 
                                className={classes.createBtn}
                                onClick={props.onCreateProjectButtonClick}>
                                { t('companyPage.buttons.createNewProject') }
                            </Button>
                        </Tooltip>
                    </Hidden>
                }
            </Toolbar>

            { companyRole.canCreateProject &&
                <Hidden smUp>
                    <Tooltip title={ t('companyPage.tooltips.createNewProject') } placement="left" arrow>
                        <Fab color="primary" 
                            aria-label="add"
                            className={classes.fab}
                            onClick={props.onCreateProjectButtonClick}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Hidden>
            }
        </React.Fragment>
    )
}

export default ProjectsToolbar;