import React from 'react';
import Color from 'color';

import Avatar from '@material-ui/core/Avatar';
import ActivityIcon from './activityIcon/ActivityIcon';
import ProductType from '../../enums/ProductType';
import ActivityStatus from '../../enums/ActivityStatus';
import { Badge, withStyles, Tooltip } from '@material-ui/core';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { useTranslation } from 'react-i18next';

const StyledBadge = withStyles((theme) => ({
    badge: {
        position: 'absolute',
        top: 10,
        right: 0,
        width: '50%',
        height: '50%',
        borderRadius: '50%',
        padding: '1em',
        backgroundColor: theme.palette.background.paper,
        border: `thin solid ${theme.palette.divider}`,
        color: theme.palette.text.secondary
    }
}))(Badge);

const getForegroundColor = (backgroundColor) => {
    backgroundColor = Color(backgroundColor);

    // (W3C 'Perceived Brightness')
    // L = (red * 0.299 + green * 0.587 + blue * 0.114) / 255
    const luma =
        (
            (backgroundColor.red() * 0.299) +
            (backgroundColor.green() * 0.587) +
            (backgroundColor.blue() * 0.114)
        ) / 225;

    const lightness = luma > 0.7 ? '30%' : '100%';
    const hslColor = Color(`hsl(0, 0%, ${lightness})`).hsl().string();

    return hslColor;
}

const ActivityAvatar = (props) => {
    const { t } = useTranslation();
    const productType = ProductType[props.productType];
    const backgroundColor = props.backgroundColor ?? ActivityStatus[props.status]?.defaultAvatarColor;
    const foregroundColor = props.foregroundColor ?? getForegroundColor(backgroundColor);

    if (productType === ProductType.focusGroupV2Av || productType === ProductType.inDepthInterviewV2Av) {
        return (
            <Tooltip title={ t(`productTypes.text.${props.productType}`) } arrow>
                <StyledBadge badgeContent={<VideocamOutlinedIcon style={{ fontSize: '1.5em' }}/>}>
                    <Avatar style={{ ...props.style, backgroundColor, display: 'inline-flex', width: '2em', height: '2em' }}>
                        <ActivityIcon productType={productType} foregroundColor={foregroundColor} style={{ width: '5em', height: 'auto' }} />
                    </Avatar>
                </StyledBadge>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={ t(`productTypes.text.${props.productType}`) } arrow>
                <Avatar style={{ ...props.style, backgroundColor, display: 'inline-flex', width: '2em', height: '2em' }}>
                    <ActivityIcon productType={productType} foregroundColor={foregroundColor} style={{ width: '5em', height: 'auto' }} />
                </Avatar>
            </Tooltip>
        );
    }
}

export default ActivityAvatar;