// A generic list item based on mui ListItem which should be used
// when showing basic info on Overview pages. 
import React from 'react';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';

const OverviewListItem = (props) => {
    const { title, value } = props;

    return (
        <ListItem>
            <ListItemText 
                primary={
                    <Typography variant="overline">{ title }</Typography>
                }
                secondary={ 
                    props.component || <Typography variant="body1">{ value }</Typography>
                } />
            <ListItemSecondaryAction>
                { props.children }
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default OverviewListItem;