import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import BasicInfo from './BasicInfo';
import ProjectActions from './ProjectActions';
import Delete from './Delete';
import { setSelectedProject } from '../../redux/actions';
import { useSelectedProject } from '../../redux/hooks';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import portalApi, { handleError } from '../../utils/portalApi';

const OverviewTab = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  
    const companyId = props.companyId;
    const companyRole = props.companyRole;
    const projectId = props.projectId;

    const selectedProject = useSelectedProject();

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        portalApi
            .get(`/api/projects/${projectId}`)
            .then(response => dispatch(setSelectedProject(response.data)))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [projectId, dispatch]);

    const loading = () => {
        return selectedProject === null ? <LoadingPlaceholder /> : null;
    }

    const main = () => (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7} lg={8} xl={9}>
                    <Paper>
                        <BasicInfo
                            project={selectedProject}
                            companyRole={companyRole} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={5} lg={4} xl={3}>
                    <Paper>
                        <ProjectActions
                            onDeleteBtnClick={() => setDeleteDialogIsOpen(true)}
                            project={selectedProject} 
                            companyRole={companyRole} />
                    </Paper>
                </Grid>
            </Grid>

            <Delete
                open={deleteDialogIsOpen} 
                projectId={selectedProject.id} 
                onCancel={() => setDeleteDialogIsOpen(false)} 
                onSuccess={() => {
                    dispatch(setSelectedProject(null));
                    history.push(`/company/${companyId}/`)
                }} />
        </Container>
    )

    return (
        loading() || main()
    )
};

export default OverviewTab;