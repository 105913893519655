import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';

import portalApi, { handleError } from '../../../utils/portalApi';
import ProgressSummary, { sortSectionKeys } from './ProgressSummary';
import SetupActivitySection from './SetupActivitySection';
import PublishActivityPanel from './PublishActivityPanel';

const SectionSizes = {
    details: 6,
    schedule: 6
};

const SetupActivity = (props) => {   
    const [validationStatus, setValidationStatus] = useState(null);

    const activityId = props.activity.details.activityId;
    const activity = props.activity;

    const loadActivityValidationStatus = useCallback(() => {
        let source = portalApi.CancelToken.source();
    
        // Execute getCompany/Project/Activity requests at the same time to avoid
        // race condition. 
        portalApi
            .post(`/api/activity/${activityId}/validate`, activity)
            .then(response => {
                setValidationStatus(response.data);
            })
            .catch(handleError);
    
        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [activityId, activity]);

    useEffect(() => {
        loadActivityValidationStatus();
    }, [loadActivityValidationStatus]);

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} styles={{ position: 'sticky' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper variant="outlined">
                                <ProgressSummary validationStatus={validationStatus} />
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper variant="outlined" style={{ padding: '1em' }}>                               
                                <PublishActivityPanel 
                                    activity={activity}
                                    validationStatus={validationStatus}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Grid container spacing={3}>
                        { validationStatus && Object.keys(validationStatus).sort(sortSectionKeys).map(key => (
                            <Grid item xs={12} md={SectionSizes[key] ?? 12} key={`setup-section-${key}`} style={{ height: '100%' }}>
                                <SetupActivitySection 
                                    section={key} 
                                    activity={props.activity}
                                    validationStatus={validationStatus[key]}
                                    onSave={props.onSave} />
                            </Grid>
                        )) }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SetupActivity;
