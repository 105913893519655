import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';
import LocalisableDate from '../../shared/LocalisableDate/LocalisableDate';

const ScheduleInfo = (props) => {
    const hasDatesSpecified = props.activity.schedule.scheduledStartDateTime && props.activity.schedule.scheduledEndDateTime;

    const duration = () => {
        if (props.activity.schedule.scheduledStartDateTime && props.activity.schedule.scheduledEndDateTime) {
            const start = DateTime.fromISO(props.activity.schedule.scheduledStartDateTime); 
            const end = DateTime.fromISO(props.activity.schedule.scheduledEndDateTime);
            const interval = Interval.fromDateTimes(start, end);
            return interval.toDuration().toFormat("h'h' mm'm'");
        } else {
            return 'n/a';
        }
    }

    const content = () => (
        <div>
            <div style={{ padding: '1.5em' }}>
                <Typography variant="subtitle2">Start Date</Typography>
                <LocalisableDate date={props.activity.schedule.scheduledStartDateTime} />
            </div>

            <Divider />

            <div style={{ padding: '1.5em' }}>
                <Typography variant="subtitle2">End Date</Typography>
                <LocalisableDate date={props.activity.schedule.scheduledEndDateTime} />
            </div>

            <Divider />

            <div style={{ padding: '1.5em' }}>
                <Typography variant="subtitle2">Duration</Typography>
                <Typography variant="body1">{ duration() }</Typography>
            </div>
        </div>
    );

    return (
        hasDatesSpecified && content()
    )
};

export default ScheduleInfo;
