import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTheme } from '@material-ui/core/styles';

import TickIcon from '@material-ui/icons/Check';
import EventIcon from '@material-ui/icons/Event';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

const ProjectStatusList = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { runningActivitiesCount, upcomingActivitiesCount, isArchived } = props.project;

    var listItems = [];

    if (runningActivitiesCount > 0) {
        listItems.push(
            <ListItem key="runningActivitiesListItem" disableGutters>
                <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
                    <DirectionsRunIcon style={{ fill: theme.palette.primary.main }}/>
                </ListItemIcon>
                <Typography variant="body2" color="primary">
                    {t('projectPage.labels.totalRunningActivities', { count: runningActivitiesCount })}
                </Typography>
            </ListItem>
        )
    }

    if (upcomingActivitiesCount > 0) {
        listItems.push(
            <ListItem key="upcomingActivitiesListItem" disableGutters>
                <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
                    <EventIcon />
                </ListItemIcon>
                <Typography variant="body2"> 
                    {t('projectPage.labels.totalUpcomingActivities', { count: upcomingActivitiesCount })}
                </Typography>
            </ListItem>
        )
    }

    if (isArchived) {
        listItems.push(
            <ListItem key="isArchivedListItem" disableGutters>
                <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
                    <TickIcon />
                </ListItemIcon>
                <Typography variant="body2">
                    <i>{t('companyPage.text.archivedProject')}</i>
                </Typography>
            </ListItem>
        )
    }

    const renderList = () => {
        return (
            <List disablePadding dense style={props.style}>
                { listItems.map(item => item) }
            </List>
        )
    }

    return (
        listItems.length > 0 ? renderList() : null
    )
}

export default ProjectStatusList;