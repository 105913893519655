import React from 'react'
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2)
    },
    loadingLabel: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1)
    }
}));

const LoadingPlaceholder = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container className={classes.container} style={{ height: props.height ?? '100vh' }}>
            <CircularProgress disableShrink />
            <Typography className={classes.loadingLabel} variant="overline">{props.children || t('common.text.loading')}</Typography>
        </Container>
    )
}

export default LoadingPlaceholder;