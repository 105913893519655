import React, { useState, useEffect } from 'react';
import IFrameResizer from 'iframe-resizer-react';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

import { apiUrl } from '../../config';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import { handleAuthenticationError } from '../../utils/portalApi';

const PortalIFrame = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const [frameIsLoaded, setFrameIsLoaded] = useState(false);
    const frameSource = `${apiUrl}${props.relativeUrl}`;

    useEffect(() => {
        const eventHandler = (event) => {
            if (event.origin === apiUrl && event.data.href) {
                // Optional function syntax
                // eslint-disable-next-line no-unused-expressions
                props.onUrlChange?.(event.data.href, event);

                // When we detect /Project/Dashboard in legacy Portal it usually means that
                // the action within the iframe has been completed.
                if (event.data.href.indexOf('/Project/Dashboard') >= 0) {
                    if (props.redirectUrl) {
                        // Return to a different page in React Portal.
                        history.push(props.redirectUrl);
                    }

                    // Optional function syntax
                    // eslint-disable-next-line no-unused-expressions
                    props.onDashboardRedirect?.();
                }

                if (event.data.href.indexOf('/Membership/LogIn') >= 0) {
                    handleAuthenticationError();
                }
            }
        };

        window.addEventListener('message', eventHandler);

        return(() => {
            // Remove eventListener when component is cleaned up.
            window.removeEventListener('message', eventHandler);
        });
    }, [history, props.redirectUrl, props.onUrlChange, props.onDashboardRedirect]);

    const handleFrameLoad = (event) => {
        if (frameIsLoaded === false) {
            setFrameIsLoaded(true);
            return;
        }
    }

    return (
        <React.Fragment>
            <Container disableGutters={props?.containerProps?.disableGutters ?? false}>
                <Paper style={{ padding: theme.spacing(2), overflow: 'auto' }} {...props?.paperProps}>
                    { !frameIsLoaded && <LoadingPlaceholder height={`auto`} /> }

                    <Fade in={frameIsLoaded}>
                        <IFrameResizer
                            heightCalculationMethod="taggedElement"
                            id={props.id}
                            width="960px"
                            height="1"
                            title={props.title ?? props.id}
                            src={frameSource}
                            onLoad={handleFrameLoad}
                            style={{ border: 'none', display: 'block', margin: 'auto' }} />
                    </Fade>
                </Paper>
            </Container>
        </React.Fragment>
    );
};

export default PortalIFrame;