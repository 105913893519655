import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ActivityStatus from '../../enums/ActivityStatus';
import BasicInfo from './BasicInfo';
import MainActivityLinks from './MainActivityLinks';
import PortalIFrame from '../shared/PortalIFrame';
import ProductType from '../../enums/ProductType';
import Delete from './Delete';
import { createStatusRelativeURL } from './activityUrlHelper';
import { setSelectedActivity } from '../../redux/actions';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import portalApi, { handleError } from '../../utils/portalApi';
import { useSelectedActivity } from '../../redux/hooks';

const OverviewTab = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    const { companyId, projectId, activityId, pageRoot } = props;
    const companyRole = props.companyRole;
    
    const activity = useSelectedActivity();

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        portalApi
            .get(`/api/activity/${activityId}`)
            .then(response => dispatch(setSelectedActivity(response.data)))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [activityId, projectId, dispatch]);

    const loading = () => {
        return activity === null ? <LoadingPlaceholder height="auto" /> : null;
    }

    const main = () => {
        const status = activity?.details.detailedStatus;
        const productType = activity?.details.activityType;

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} lg={8} xl={9}>
                        <Paper>
                            <BasicInfo
                                activity={activity}
                                companyRole={companyRole}
                                onRescheduleBtnClick={() => {
                                    history.push(`${pageRoot}/setup/reschedule`)
                                }} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={5} lg={4} xl={3}>
                        <Paper>
                            <MainActivityLinks
                                onDeleteBtnClick={() => setDeleteDialogIsOpen(true)}
                                activity={activity} 
                                companyRole={companyRole}
                                style={{ paddingTop: theme.spacing(2) }} />
                        </Paper>
                    </Grid>
                </Grid>

                { ActivityStatus[status].shouldShowStatus && ProductType[productType].shouldShowStatus &&
                    <Grid item xs={12}>
                        <Paper style={{ marginTop: theme.spacing(2) }}>
                            <PortalIFrame
                                containerProps={{ disableGutters: true }}
                                id={'activityStatusIframe'}
                                relativeUrl={createStatusRelativeURL(ProductType[productType], projectId, activityId)}
                                redirectUrl={`${pageRoot}/overview`} />
                        </Paper>
                    </Grid>
                }

                <Delete
                    open={deleteDialogIsOpen} 
                    activityId={activityId} 
                    projectId={projectId} 
                    onCancel={() => setDeleteDialogIsOpen(false)} 
                    onSuccess={() => {
                        dispatch(setSelectedActivity(null));
                        history.push(`/company/${companyId}/project/${projectId}/activities`)
                    }} />
            </React.Fragment>
        )
    }        

    return(
        loading() || main()
    )
}

export default OverviewTab;