import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const RespondentsInfo = (props) => {
    const respondents = props.activity.respondents.available.filter(respondent => props.activity.respondents.participants.includes(respondent.id));
    const pseudonymColumnIsVisible = props.activity.respondents.available.find(respondent => respondent.pseudonym);

    const respondentsTable = () => (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    { pseudonymColumnIsVisible && <TableCell>Pseudonym</TableCell> }
                    <TableCell>Email</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    respondents.map(respondent => {
                        return(
                            <TableRow key={respondent.id} hover>
                                <TableCell>{ `${respondent.firstName} ${respondent.lastName}` }</TableCell>
                                { pseudonymColumnIsVisible && <TableCell>{ respondent.pseudonym }</TableCell> }
                                <TableCell>{ respondent.email }</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )

    return (
        respondents.length > 0 && respondentsTable()
    )
}

export default RespondentsInfo;