import React from 'react';
import { List, ListSubheader, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import LoadingPlaceholder from '../../shared/LoadingPlaceholder';
import { useTranslation } from 'react-i18next';

const sectionOrder = [
    'details',
    'schedule',
    'content',
    'moderators',
    'observers',
    'respondents',
    'notifications'
];

export const sortSectionKeys = (a, b) => {
    return sectionOrder.indexOf(a) - sectionOrder.indexOf(b);
}

const getStatusDisplay = (status) => {
    switch (status?.validity) {
    case "okay": 
        return {
            color: '#599B75',
            icon: <CheckIcon style={{ width: '60%', height: 'auto' }} />
        };
    case "warning":
        return {
            color: 'orange',
            icon: <ErrorOutlineIcon style={{ width: '60%', height: 'auto' }} />
        }
    case "todo": 
        return {
            color: '#F44336',
            icon: <CloseIcon style={{ width: '60%', height: 'auto' }} />
        };
    default: 
        return {
            color: 'grey',
            icon: <InfoIcon style={{ width: '60%', height: 'auto' }} />
        };
    }
}

const ProgressSummary = (props) => {
    const { t } = useTranslation();

    const sections = () => {
        return Object.keys(props.validationStatus).sort(sortSectionKeys).map(key => {
            const title = t(`activitySetupSections.text.${key}`);
            const statusDisplayInfo = getStatusDisplay(props.validationStatus[key]);

            return (
                <ListItem key={`progress-summary-section-${key}`}>
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: statusDisplayInfo.color }}>
                            { statusDisplayInfo.icon }
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body1">{ title }</Typography>} />
                </ListItem>
            )
        })
    }

    return (
        <List>
            <ListSubheader>Progress Summary</ListSubheader>
            { props.validationStatus !== null ? sections() : <LoadingPlaceholder height="200" /> }
        </List>
    );
}

export default ProgressSummary;
