// Really simple application of a Mui Snackbar that can be reused.
// Simply pass in a notification object { key: string, message: string } and handle the 'onClose' event.
// The snackbar will close automatically after a few seconds.

import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';

const BasicSnackbar = (props) => {
    const { notification } = props;

    return (
        <Snackbar
            key={notification && notification.key}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={Boolean(notification)}
            autoHideDuration={3000}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={notification && notification.message}
            onClose={props.onClose} />
    );
}

export default BasicSnackbar;