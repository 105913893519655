import React from 'react';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import RescheduleIcon from '@material-ui/icons/History';
import ActivityAvatar from '../shared/ActivityAvatar';
import ActivityStatus from '../../enums/ActivityStatus';
import OverviewListItem from '../shared/OverviewListItem';
import LocalisableDate from '../shared/LocalisableDate/LocalisableDate';
import { Typography, useTheme } from '@material-ui/core';

const RescheduleButton = (props) => {
    const { t } = useTranslation();
    const companyRole = props.companyRole;
    const status = ActivityStatus[props.activityStatus];

    return (
        companyRole.canSetupActivity && status === ActivityStatus.didNotStart ?
            <Button 
                startIcon={<RescheduleIcon />} 
                variant="contained"
                color="primary"
                onClick={props.onClick}>
                { t('activityPage.buttons.rescheduleActivity') }
            </Button>
            :
            null
    )
}

const BasicInfo = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const companyRole = props.companyRole;
    const { activityType } = props.activity.details;
    const { scheduledStartDateTime, scheduledEndDateTime } = props.activity.schedule;
    const status = props.activity.details.detailedStatus;

    return (
        <List>
            <OverviewListItem title={ t('common.labels.entityType') } value={ t(`productTypes.text.${activityType}`) } >
                <ActivityAvatar
                    productType={activityType}
                    backgroundColor={ActivityStatus[status].defaultAvatarColor}
                    style={{ height: '2.5em', width: '2.5em' }} />
            </OverviewListItem>
            <Divider />

            <OverviewListItem title={ t('common.labels.entityStatus') } value={ t(`activityStatuses.text.${status}`) }>
                <RescheduleButton activityStatus={status} companyRole={companyRole} onClick={props.onRescheduleBtnClick} />
            </OverviewListItem>
            <Divider />

            <div style={{ padding: theme.spacing(2) }}>
                <Typography variant="overline">{ t('activityPage.labels.activityStartTime') }</Typography>
                <LocalisableDate date={scheduledStartDateTime} />
            </div>
            <Divider />

            <div style={{ padding: theme.spacing(2) }}>
                <Typography variant="overline">{ t('activityPage.labels.activityEndTime') }</Typography>
                <LocalisableDate date={scheduledEndDateTime} />
            </div>
        </List>
    );
};

export default BasicInfo;