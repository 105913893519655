import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { Divider, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { ReactComponent as Logo } from '../../sidebar-logo.svg';
import { useSelectedActivity, useSelectedProject, useSelectedCompany } from '../../redux/hooks';
import SelectedCompanyButton from './SelectedCompanyButton';
import SelectedProjectButton from './SelectedProjectButton';
import SelectedActivityButton from './SelectedActivityButton';
import { setIsMenuOpen } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    list: {
        overflow: 'hidden'
    },
    logo: {
        width: '100%',
        height: 'auto',
        '& .st0': {
            fill: theme.sidebar.defaultLogoBackgroundColor || theme.palette.primary.main
        },
        '& .st1': {
            fill: theme.sidebar.defaultLogoForegroundColor || theme.palette.primary.contrastText
        },
        lineHeight: 0,
        display: 'block'
    },
    footer: {
        textAlign: 'center',
        color: theme.sidebar.contrastText,
        '& .MuiButton-root': {
            color: theme.sidebar.contrastText,
            borderColor: theme.sidebar.contrastText
        }
    }
}));

const SidebarDivider = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))(Divider);

function SidebarContent() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const selectedCompany = useSelectedCompany();
    const selectedProject = useSelectedProject();
    const selectedActivity = useSelectedActivity();
    const companyCount = useSelector(state => state.UserDetailsReducer?.companyCount) ?? 0;

    const dispatch = useDispatch();

    const closeSidebar = () => {
        dispatch(setIsMenuOpen(false));
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: '1em'
        }}>
            <div>
                { theme.sidebar.logo ?
                    <img src={theme.sidebar.logo} className={classes.logo} alt="Company Logo" /> :
                    <Logo className={classes.logo} />
                }

                { Boolean(selectedCompany) && companyCount > 1 &&
                    <React.Fragment>
                        <SidebarDivider />
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                history.push("/selectCompany");
                                closeSidebar();
                            }}>{ t('sidebar.buttons.changeSelectedCompany') }</Button>
                    </React.Fragment>
                }

                <SidebarDivider />

                <List className={classes.list} disablePadding>
                    <SelectedCompanyButton company={selectedCompany} onClick={() => {
                        history.push(selectedCompany ? `/company/${selectedCompany.id}` : "/selectCompany");
                        closeSidebar()
                    }} />

                    <Collapse in={Boolean(selectedProject)}>
                        { selectedProject &&
                            <SelectedProjectButton
                                project={selectedProject}
                                onClick={() => {
                                    history.push(`/company/${selectedCompany.id}/project/${selectedProject.id}/activities`);
                                    closeSidebar();
                                }} />
                        }
                    </Collapse>

                    { selectedActivity &&
                        <SelectedActivityButton
                            activity={selectedActivity}
                            onClick={() => {
                                history.push(`/company/${selectedCompany.id}/project/${selectedProject.id}/activity/${selectedActivity.details.activityId}`);
                                closeSidebar();
                            }}
                        />
                    }
                </List>
            </div>

            <div className={classes.footer}>
                <Tooltip title={ t('sidebar.tooltips.openAppInfo') } arrow>
                    <Button startIcon={<InfoIcon />} onClick={() => history.push('/about')} style={{ textTransform: 'none' }}>
                        VisionsLive Portal v{ process.env.REACT_APP_VERSION }
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
}

export default SidebarContent;