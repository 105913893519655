import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import deepEqual from 'deep-equal';

import Container from '@material-ui/core/Container';

import { NoActiveActivities, NoActivitiesFound } from './EmptyActivitiesPlaceholder';
import LoadingPlaceholder from '../../shared/LoadingPlaceholder';

import ActivitiesTableView from './TableView';
import ActivityStatus from '../../../enums/ActivityStatus';
import portalApi, { handleError } from '../../../utils/portalApi';
import ActivitiesToolbar from './Toolbar';
import { handleKeyDown } from '../../../utils/keyboardShortcutHelper';
import CreateActivityModal from './CreateActivityModal';
import { FlexHeightInnerContainer, ToolbarContainer } from '../../shared/LayoutComponents';
import CompletedActivitiesTableView from './CompletedActivitiesTableView';

export const DEFAULT_FILTER = {
    searchText: "",
    shouldShowCompletedActivities: false
};

const refreshActivities = (projectId, setAllActivities) => {
    portalApi
        .get(`/api/activities?projectId=${projectId}`)
        .then(response => setAllActivities(response.data))
        .catch(handleError);
}

const ActivitiesTab = (props) => {
    const history = useHistory();

    const [allActivities, setAllActivities] = useState(null);
    const [filter, setFilter] = useState(DEFAULT_FILTER);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [isCreatingActivity, setIsCreatingActivity] = useState(false);

    const companyId = props.companyId;
    const projectId = props.projectId;
    const onActivitiesLoaded = props.onActivitiesLoaded;

    const bySearchTerm = (activity) => {
        return activity.name.toLowerCase().indexOf(filter.searchText.toLowerCase()) >= 0
    }

    const byStatusFilter = (activity) => {
        if (filter.shouldShowCompletedActivities) {
            return true;
        } else {
            return ActivityStatus[activity.status] !== ActivityStatus.completed;
        }
    }

    const byScheduledDate = (a, b) => {
        if (a.startDate === b.startDate) {
            return 0;
        }

        return a.startDate > b.startDate || a.startDate === null ? 1 : -1;
    }

    const getCompletedActivities = () => {
        return allActivities
            .filter(bySearchTerm)
            .filter(a => a.status === 'completed')
    }

    const statusPriorities = {
        completed: 8,
        running: 7,
        readyToStart: 6,
        upcoming: 5,
        draft: 4,
        cannotStart: 3,
        didNotStart: 2
    }

    const byStatusSort = (a, b) => {
        return statusPriorities[b.status] - statusPriorities[a.status];
    }

    const getActivities = () => {
        return allActivities
            .filter(bySearchTerm)
            .filter(byStatusFilter)
            .sort(byScheduledDate)
            .sort(byStatusSort);
    }

    const onActivitySelected = useCallback(activity => {
        history.push(`activity/${activity.activityId}`);
    }, [history]);

    const onCreateActivityButtonClick = useCallback(() => {
        setIsCreatingActivity(true);
    }, [])

    const renderActivities = () => {
        if (!allActivities) {
            return (<LoadingPlaceholder height={'auto'} />);
        } else if (getActivities().length === 0) {
            return deepEqual(filter, DEFAULT_FILTER) ?
                <NoActiveActivities onCreateActivityButtonClick={onCreateActivityButtonClick} /> :
                <NoActivitiesFound onCreateActivityButtonClick={onCreateActivityButtonClick} />
        } else {
            // Group activities by status
            const grouped = getActivities().reduce((result, activity) => {
                if (result[activity.status]) {
                    result[activity.status].push(activity);
                } else {
                    result[activity.status] = [activity];
                }

                return result;
            }, {});

            return Object.keys(statusPriorities)
                .filter(status => grouped[status])
                .map((status, index) => (
                    <div key={`activity-group-${status}`} style={{ marginBottom: '2em' }}>
                        { status === 'completed' &&
                            <CompletedActivitiesTableView
                                projectId={projectId}
                                activities={grouped[status]}
                                handleSelection={onActivitySelected}
                                selectedItemIndex={getActivities()[selectedItemIndex]?.activityId} />
                        }

                        { status !== 'completed' &&
                            <ActivitiesTableView
                                status={status}
                                activities={grouped[status]}
                                handleSelection={onActivitySelected}
                                selectedItemIndex={getActivities()[selectedItemIndex]?.activityId} />
                        }
                    </div>
                ));
        }
    };

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        refreshActivities(projectId, setAllActivities);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [projectId, onActivitiesLoaded]);

    return (
        <FlexHeightInnerContainer disablePadding>
            <ToolbarContainer>
                <Container>
                    <ActivitiesToolbar
                        totalCompletedActivities={allActivities ? getCompletedActivities().length : 0}
                        onSearchChange={(searchText) => {
                            setSelectedItemIndex(null);
                            setFilter({ ...filter, searchText });
                        }}
                        onShouldShowCompletedActivitiesChange={(shouldShow) => {
                            setSelectedItemIndex(null);
                            setFilter({ ...filter, shouldShowCompletedActivities: shouldShow });
                        }}
                        onCreateActivityButtonClick={onCreateActivityButtonClick}
                        searchBoxProps={{
                            onKeyDown: (e) => {
                                handleKeyDown(e, getActivities(), selectedItemIndex, setSelectedItemIndex, onActivitySelected);
                            }
                        }}
                    />
                </Container>
            </ToolbarContainer>

            <FlexHeightInnerContainer>
                <Container>
                    { renderActivities() }
                </Container>
            </FlexHeightInnerContainer>

            <CreateActivityModal
                companyId={companyId}
                projectId={projectId}
                heading={'New Activity'}
                open={isCreatingActivity}
                onSuccess={(newActivity) => {
                    const newActivityId = newActivity.details.activityId;
                    history.push(`activity/${newActivityId}/setup`);
                }}
                onCancel={() => {
                    setAllActivities(null);
                    refreshActivities(projectId, setAllActivities);
                    setIsCreatingActivity(false)
                }} />
        </FlexHeightInnerContainer>
    );
};

export default ActivitiesTab;