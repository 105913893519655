import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

// Customised tabs to make more appropriate for display in the TopBar
const ThemedTabs = withStyles(theme => ({
    root: {
        '& .Mui-selected': {
            color: theme.palette.primary.main
        }
    }
}))(Tabs);

const TopBarTabs = (props) => (
    <ThemedTabs {...props} indicatorColor="primary">
        {props.children}
    </ThemedTabs>
)

export default TopBarTabs;