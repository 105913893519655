import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { CardActionArea } from '@material-ui/core';

import ActivityIcon from '../shared/activityIcon/ActivityIcon';
import ProductType from '../../enums/ProductType';
import TestBackground from './productCards/focus-group.jpg';
import { useCardStyles } from './UseCardStyles';
import AVProductCardActions from './AVProductCardActions';

const FocusGroupProductCard = (props) => {
    const { t } = useTranslation();
    const classes = useCardStyles({ color: '#32615E' });

    return (
        <Card className={classes.card}>
            <CardActionArea className={classes.cardActionArea} disabled={true}>
                <Avatar className={classes.iconAvatar}>
                    <ActivityIcon productType={ProductType.focusGroupV2Av} />
                </Avatar>

                <CardMedia
                    image={TestBackground}
                    title={t('productTypes.text.focusGroup')}
                    className={classes.media} />

                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        { t('productTypes.text.focusGroup') }
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        { t('productDescriptions.text.focusGroup') }
                    </Typography>
                </CardContent>
            </CardActionArea>

            <AVProductCardActions
                productType={ProductType.focusGroupV2}
                avProductType={ProductType.focusGroupV2Av}
                onClick={props.onClick} />
        </Card>
    )
};

export default React.memo(FocusGroupProductCard);