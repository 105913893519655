import React from 'react';
import { DialogContent, DialogActions, Button, Divider, Typography } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon';

import portalApi, { handleError } from '../../../utils/portalApi';
import ResponsiveDialog from '../../shared/ResponsiveDialog';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const ScheduleModalDatePicker = (props) => {
    return (
        <KeyboardDateTimePicker
            { ...props }
            clearable
            placeholder='dd/mm/yyyy hh:mm'
            disablePast
            fullWidth
            ampm={false}
            inputVariant="outlined"
            format="dd/MM/yyyy HH:mm" />
    )
};

const FormContent = () => {
    const { values, setFieldValue } = useFormikContext();

    const scheduledStartDateTime = values.scheduledStartDateTime !== '' ? DateTime.fromISO(values.scheduledStartDateTime) : null;
    const scheduledEndDateTime = values.scheduledEndDateTime !== '' ? DateTime.fromISO(values.scheduledEndDateTime) : null;

    return(
        <React.Fragment>
            <div style={{ marginBottom: '2em' }}>
                <Typography variant="h6" gutterBottom>Start Date</Typography>
                <ScheduleModalDatePicker 
                    value={scheduledStartDateTime} 
                    onChange={(date) => {
                        setFieldValue('scheduledStartDateTime', date?.toISO() ?? '');
                    }} />
            </div>

            <div>
                <Typography variant="h6" gutterBottom>End Date</Typography>
                <ScheduleModalDatePicker
                    value={scheduledEndDateTime}
                    disabled={scheduledStartDateTime === ''}
                    minDate={scheduledStartDateTime}
                    onChange={(date) => {
                        setFieldValue('scheduledEndDateTime', date?.toISO() ?? '');
                    }} />
            </div>
        </React.Fragment>
    );
}

const FormActions = (props) => {
    const { handleSubmit, dirty, isSubmitting, isValid } = useFormikContext();

    return (
        <DialogActions>
            <Button size="large" onClick={props.onCancel}>
                Cancel
            </Button>
            <Button 
                variant="contained" 
                size="large"
                onClick={handleSubmit} 
                color="primary" 
                disabled={!isValid || isSubmitting || !dirty}>
                Save Changes
            </Button>
        </DialogActions>
    )
}

const EditScheduleModal = (props) => {
    const activityId = props.activity.details.activityId;
    
    const validationSchema = Yup.object().shape({
        scheduledStartDateTime: Yup.string().required(),
        scheduledEndDateTime: Yup.string().required()
    });

    const handleSubmit = (values, actions) => {
        portalApi
            .put(`/api/activity/${activityId}/schedule`, {
                scheduledStartDateTime: values.scheduledStartDateTime,
                scheduledEndDateTime: values.scheduledEndDateTime
            })
            .then(response => {
                props.onSave();
            })
            .catch(handleError);
    };

    return (
        <Formik
            initialValues={{
                scheduledStartDateTime: props.activity.schedule.scheduledStartDateTime,
                scheduledEndDateTime: props.activity.schedule.scheduledEndDateTime
            }}
            validationSchema={validationSchema}
            validate={(values) => {
                const errors = {};
                
                if (values.scheduledEndDateTime <= values.scheduledStartDateTime) {
                    errors.scheduledEndDateTime = "Must be after start date.";
                }

                return errors;
            }}
            onSubmit={handleSubmit}
        >
            <ResponsiveDialog heading={"Schedule Activity"} open={props.open} onCancel={props.onCancel}>
                <DialogContent style={{ backgroundColor: '#f5f5f5', paddingTop: '2em', paddingBottom: '2em' }}>
                    <FormContent />
                </DialogContent>

                <Divider />
                <FormActions onCancel={props.onCancel} />
            </ResponsiveDialog>
        </Formik>
    );
}

export default EditScheduleModal;
