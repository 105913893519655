import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Mui icons
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/HelpRounded';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import LaunchIcon from '@material-ui/icons/Launch';

import { apiUrl } from "../../../config";
import UserAvatar from '../UserAvatar';
import { useSelector } from 'react-redux';

const UserMenu = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const userDetails = useSelector(state => state.UserDetailsReducer?.userDetails);

    return (
        <Menu
            id="user-menu"
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={props.onClose}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1em', width: '20em' }}>
                <UserAvatar style={{ height: '2.4em', width: '2.4em', fontSize: '2rem', display: 'inline-flex', marginBottom: '0.4em' }} />
                <Typography variant="h6">{ userDetails.firstName } { userDetails.lastName}</Typography>
                <Typography variant="body2">{ userDetails.email }</Typography>
            </div>

            <Divider style={{ margin: '1em', marginTop: 0 }} />

            <MenuItem
                onClick={() => {
                    window.open(`${apiUrl}/Company/Choose?from=app&page=${encodeURIComponent(window.location.pathname)}`, "_blank");
                }}
            >
                <ListItemIcon>
                    <LaunchIcon />
                </ListItemIcon>
                { t('userMenu.buttons.openLegacyPortalApplication') }
            </MenuItem>

            <Divider style={{ margin: '1em' }} />

            <MenuItem
                onClick={() => {
                    history.push('/currentUser/accountSettings');
                    props.onClose();
                }}
            >
                <ListItemIcon>
                    <PersonIcon fontSize="small" />
                </ListItemIcon>
                { t( 'userMenu.buttons.openUserAccountSettings') }
            </MenuItem>

            <MenuItem
                onClick={() => {
                    window.open("https://www.visionslive.com/support/", "_blank");
                }}
            >
                <ListItemIcon>
                    <HelpIcon fontSize="small" />
                </ListItemIcon>
                { t('common.buttons.openHelp') }
            </MenuItem>

            <MenuItem
                onClick={() => {
                    window.location.href = `${apiUrl}/Membership/LogOff`;
                }}
            >
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                { t('common.buttons.logout') }
            </MenuItem>
        </Menu>
    )
}

export default UserMenu;