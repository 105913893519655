import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import ActivityAvatar from '../../shared/ActivityAvatar';
import SelectableTableRow from '../../shared/SelectableTableRow';
import LocalisableDate from '../../shared/LocalisableDate/LocalisableDate';
import { Typography } from '@material-ui/core';

const ActivityTableRow = (props) => {
    const { activity } = props;

    return (
        <SelectableTableRow key={activity.activityId} hover={true} onClick={props.onClick} selected={props.selected}>
            <TableCell>
                <ActivityAvatar
                    productType={activity.productType}
                    status={activity.status} />
            </TableCell>
            <TableCell component="th" scope="row" style={{ width: '100%' }}>
                <Typography variant="inherit">{activity.name}</Typography>
            </TableCell>
            <TableCell>
                <LocalisableDate date={activity.startDate} typographyProps={{ variant: 'inherit' }} />
            </TableCell>
        </SelectableTableRow>
    )
};

export default React.memo(ActivityTableRow);