import React, { useEffect, useMemo } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';

import ProjectsIcon from '@material-ui/icons/Folder';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { setSelectedActivity, setSelectedCompany, setSelectedProject } from '../../redux/actions';
import { useSelectedCompany } from '../../redux/hooks';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import portalApi, { handleError } from '../../utils/portalApi';
import TopBar from '../shared/TopBar';
import TopBarTabs from '../shared/TopBarTabs';
import PortalIFrame from '../shared/PortalIFrame';
import { useTranslation } from 'react-i18next';
import ProjectsTab from './projects/ProjectsTab';
import CompanyRole from '../../enums/CompanyRole';
import { FlexHeightContainer, FlexHeightInnerContainer } from '../shared/LayoutComponents';

const getTabs = (companyRole, t) => {
    return [
        {
            key: 'projects',
            label: t('companyPage.tabs.manageProjects'),
            icon: <ProjectsIcon />,
            enabled: true
        },
        {
            key: 'users',
            label: t('companyPage.tabs.manageUsers'),
            icon: <GroupIcon />,
            enabled: companyRole?.canManageUsers
        },
        {
            key: 'usage-and-billing',
            label: t('companyPage.tabs.manageUsageAndBilling'),
            icon: <WalletIcon />,
            enabled: companyRole?.canManageUsageAndBilling
        },
        {
            key: 'settings',
            label: t('companyPage.tabs.companySettings'),
            icon: <SettingsIcon />,
            enabled: companyRole?.canManageSettings
        },
    ];
}

const CompanyOverview = ({ match }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const companyId = match.params.company_guid;
    const dispatch = useDispatch();

    const selectedCompany = useSelectedCompany();

    const tabs = useMemo(() => getTabs(CompanyRole[selectedCompany?.role], t), [selectedCompany, t]);
    const pageRoot = `/company/${match.params.company_guid}`

    useEffect(() => {
        dispatch(setSelectedProject(null));
        dispatch(setSelectedActivity(null));
    }, [dispatch]);

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        portalApi
            .get(`/api/companies/${companyId}`)
            .then(response => {
                dispatch(setSelectedCompany(response.data));
            })
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [companyId, dispatch]);

    const routes = () => {
        return (
            <React.Fragment>
                <Route path={`${pageRoot}/projects`} exact>
                    <FlexHeightInnerContainer disablePadding>
                        <ProjectsTab companyId={companyId} />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={`${pageRoot}/users`}>
                    <FlexHeightInnerContainer>
                        <PortalIFrame
                            id={'companyUsersIframe'}
                            relativeUrl={`/x/${selectedCompany.id}/CompanyUsers`}
                            redirectUrl={`${pageRoot}/activities`}
                        />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={`${pageRoot}/usage-and-billing`}>
                    <FlexHeightInnerContainer>
                        <PortalIFrame
                            id={'companyUsageAndBillingIframe'}
                            relativeUrl={`/Account/x/${selectedCompany.id}/Billing`}
                            redirectUrl={`${pageRoot}/activities`}
                        />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={`${pageRoot}/settings`}>
                    <FlexHeightInnerContainer>
                        <PortalIFrame
                            id={'companySettingsIframe'}
                            relativeUrl={`/x/${selectedCompany.id}/TranscriptionSettings`}
                            redirectUrl={`${pageRoot}/activities`}
                        />
                    </FlexHeightInnerContainer>
                </Route>

                <Route path={pageRoot} exact>
                    <Redirect from={pageRoot} to={`${pageRoot}/projects`} exact />
                </Route>
            </React.Fragment>
        )
    }

    const loading = () => (
        selectedCompany ? null : <LoadingPlaceholder />
    )

    const main = () => (
        <FlexHeightContainer>
            <TopBar
                breadcrumbs={[
                    {
                        title: selectedCompany.name
                    }
                ]}
                scrollElevation={ match.params.tab === "projects" ? 0 : 4 }>
                <Container>
                    <TopBarTabs
                        value={Math.max(tabs.findIndex((tab) => tab.key === match.params.tab), 0)}
                        onChange={(event, newValue) => {
                            const targetUrl = `/company/${match.params.company_guid}/${tabs[newValue].key}`
                            history.push(targetUrl);
                        }}
                        variant="scrollable"
                    >
                        { tabs.filter(tab => tab.enabled).map(tab =>
                            <Tab key={tab.key} label={tab.label} />
                        )}
                    </TopBarTabs>
                </Container>
            </TopBar>

            { routes() }
        </FlexHeightContainer>
    )

    return (
        loading() || main()
    );
}

export default CompanyOverview;