import React from 'react';
import { Divider, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export const KeyValueThing = (props) => {
    return (
        <div style={{ padding: '1.5em', display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{ props.label }</Typography>
                <Typography variant="body1">{props.value}</Typography>
            </div>
            <IconButton style={{ visibility: 'hidden' }}><EditIcon /></IconButton>
        </div>
    )
}

const BasicInfo = (props) => {
    const { languageId, languages } = props.activity.details;
    const languageDisplayName = languages.find(l => l.key === languageId).value;

    return (
        <div>
            <KeyValueThing label={'Title'} value={props.activity.details.title} />
            <Divider />

            <KeyValueThing label={'Language'} value={languageDisplayName} />
            <Divider />
            
            <KeyValueThing label={'Description'} value={props.activity.details.description} />
        </div>
    );
}

export default BasicInfo;