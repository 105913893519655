import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
    avatar: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        fontSize: '1rem',
        cursor: (props) => (props.onClick ? 'pointer' : 'auto')
    }
}));

/** Displays the initials of the currently logged in user. These are retrieved from the global redux state,
 *  and so this component must be contained within a suitable provider. */
const UserAvatar = (props) => {
    const classes = useStyles(props);
    const userDetails = useSelector(state => state.UserDetailsReducer?.userDetails);

    return (
        <Avatar className={classes.avatar} style={props.style} onClick={props.onClick}>
            { userDetails?.firstName?.charAt(0) ?? "?" }{ userDetails?.lastName?.charAt(0) }
        </Avatar>
    )
}

UserAvatar.propTypes = {
    /**
     * Handle click events.
     *
     * _Note: The cursor will switch to 'pointer' when this callback is provided._
     * */
    onClick: PropTypes.func,
    /** Override component styles */
    style: PropTypes.object
};

export default UserAvatar;