import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { listItemStyles, selectedListItemStyles } from './SidebarListItemStyles';

const SelectProjectSection = (props) => {
    const { project } = props;
    const { t } = useTranslation();
    
    const routeIsActive = useRouteMatch({
        path: '/company/:company_guid/project/:project_guid/:tab?',
        strict: true,
        exact: true
    });

    const classes = routeIsActive ? selectedListItemStyles() : listItemStyles();

    return (
        <React.Fragment>
            <ListItem 
                button 
                key='selectProject' 
                className={classes.listItem}
                onClick={props.onClick}
            >
                <ListItemText
                    primary={t('sidebar.labels.selectedProject')}
                    primaryTypographyProps={{ className: classes.primaryText }}
                    secondary={project.name}
                    secondaryTypographyProps={{ className: classes.secondaryText }}
                />
            </ListItem>
        </React.Fragment>
    );
}

export default SelectProjectSection;