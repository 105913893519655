import { Box, withStyles } from '@material-ui/core';

// Convenience (top-level) component for use in views that will have a flex (column) layout and take up 100% of
// available height.
export const FlexHeightContainer = withStyles(theme => ({
    root: {
        maxHeight: '100vh',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    }
}))(Box);

// Another convenience component for use when nesting within a FlexHeightContainer. It's designed
// to fill available vertical space (via flex-grow), and should be used for any non-static (and scrollable)
// content such as lists etc.
//
// When multiple levels of flexible height are needed, these should be nested within one another.
export const FlexHeightInnerContainer = withStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'auto',
        paddingTop: props => props.disablePadding ? 0 : theme.spacing(2),
        paddingBottom: props => props.disablePadding ? 0 : theme.spacing(2),
        // Enable ContentContainers to be nested.
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
    }
}))(Box);

// Container for in-page toolbar (not to be confused with app bar). These toolbar's typically contain tab-specific
// functionality, such as list options or 'Create New' buttons.
export const ToolbarContainer = withStyles(theme => ({
    root: {
        borderBottom: `thin dashed ${theme.palette.divider}`,
        backgroundColor: theme.toolbar.backgroundColor
    }
}))(Box);