import React, { useState } from 'react';
import { Typography, Divider, Collapse, Button } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import { MemoisedTimezonePicker } from './TimezonePicker';
import { useCallback } from 'react';
import DetailedTimezone from './DetailedTimezone';

import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import api, { handleError } from '../../../utils/portalApi';
import { setUserTimezone } from '../../../redux/actions';

const TimezoneSettings = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isChangingTimezone, setIsChangingTimezone] = useState(false);

    const selectedTimezone = useSelector(state => state.UserDetailsReducer?.userDetails.ianaTimezoneId);
    const localTimezone = DateTime.local().zoneName;

    const { onTimezoneChanged } = props;

    const onSelectTimezone = useCallback((timezone) => {
        setIsChangingTimezone(false);

        api
            .put('/api/currentuser/timezone', { timezone })
            .then((response) => {
                dispatch(setUserTimezone(timezone));
                
                if (onTimezoneChanged) {
                    onTimezoneChanged(timezone);
                }
            })
            .catch(handleError);
    }, [onTimezoneChanged, dispatch]);
    
    const timezonesDiffer = () => {
        if (selectedTimezone === null) {
            return false;
        }

        return selectedTimezone !== localTimezone;
    }

    return (
        <React.Fragment>
            { !props.titleIsHidden &&
                <Typography variant="h6" gutterBottom>
                    { t('timezoneSettings.text.changeYourTimezoneHeading') }
                </Typography>
            }

            { timezonesDiffer() && 
                <Typography variant="body1">
                    <HelpIcon fontSize="inherit" /> { t('timezoneSettings.text.timezoneMismatchWarning') }
                </Typography>
            }

            <Divider style={{ marginTop: '1em', marginBottom: '1em' }}/>

            <Typography variant="caption" display="block">
                { t('timezoneSettings.labels.yourChosenTimezone') }
            </Typography>
            <DetailedTimezone date={props.date} timezone={selectedTimezone} />

            <Collapse in={isChangingTimezone}>
                <MemoisedTimezonePicker 
                    currentTimezone={selectedTimezone ?? localTimezone}
                    onSelect={onSelectTimezone} />
            </Collapse>

            { isChangingTimezone === false && 
                <Button 
                    fullWidth 
                    variant="contained"
                    onClick={() => setIsChangingTimezone(true)}>{ t('timezoneSettings.buttons.changeMyTimezone') }</Button>
            }

            { timezonesDiffer() && 
                <React.Fragment>
                    <Divider style={{ marginTop: '1em', marginBottom: '1em' }}/>

                    <Typography variant="caption" display="block">
                        { t('timezoneSettings.labels.detectedTimezone') }
                    </Typography>
                    <DetailedTimezone date={props.date} />

                    <Button fullWidth variant="contained" color="primary"
                        onClick={() => {
                            onSelectTimezone(localTimezone);
                        }}
                    >
                        { t('timezoneSettings.buttons.useThisTimezone') }
                    </Button>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default TimezoneSettings;