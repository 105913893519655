import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useTheme, withStyles } from '@material-ui/core/styles';

const ThemedTextField = withStyles(theme => ({
    root: {
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.paper
        }
    }
}))(TextField);

const SearchBox = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ThemedTextField
            id="standard-search"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" disablePointerEvents>
                        <SearchIcon style={{ fill: theme.palette.divider }} />
                    </InputAdornment>
                )
            }}
            hiddenLabel={true}
            placeholder={t('common.labels.filterListOfEntities')}
            type="search"
            value={props.value}
            size="small"
            onChange={props.onChange}
            autoFocus={props.autoFocus || true}
            variant='outlined'
            autoComplete="off"
            onKeyDown={props.onKeyDown}
        />
    )
}

export default SearchBox;