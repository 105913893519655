import React from 'react';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DeleteIcon from '@material-ui/icons/Delete';

const ProjectActions = (props) => {
    const { t } = useTranslation();
    const companyRole = props.companyRole;

    return (
        <List component="nav" aria-label="contacts">
            <ListSubheader>{ t('projectPage.text.projectActionsHeading') }</ListSubheader>
           
            { companyRole.canDeleteProject &&
                <ListItem button onClick={props.onDeleteBtnClick}>
                    <ListItemIcon>
                        <DeleteIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                        primaryTypographyProps={{ color: "primary" }} 
                        primary={ t('projectPage.buttons.deleteProject') } />
                </ListItem>
            }
        </List>
    )
};

export default ProjectActions;