import React, { useState } from 'react';

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';

import portalApi, { handleError } from '../../utils/portalApi';

const Delete = (props) => {
    const theme = useTheme();
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const handleDelete = (event) => {
        if (deleteInProgress) { 
            return; 
        }

        setDeleteInProgress(true);

        portalApi
            .delete(`/api/projects/${props.projectId}`)
            .then(() => props.onSuccess())
            .catch(handleError)
    }
 
    return (
        <Dialog
            open={props.open}
            onClose={() => {}}
            aria-labelledby="delete-confirmation-title"
            aria-describedby="delete-description"
        >
            <DialogTitle id="delete-confirmation-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-description">
                    Are you sure you want to delete this project?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!deleteInProgress &&
                    <React.Fragment>
                        <Button onClick={props.onCancel}>
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="primary">
                            Yes
                        </Button>
                    </React.Fragment>
                }

                {deleteInProgress &&
                    <React.Fragment>
                        <Button disabled>Deleting</Button>
                        <CircularProgress size={20} style={{ marginRight: theme.spacing(2) }} />
                    </React.Fragment>
                }
            </DialogActions>
        </Dialog>
    )
}

export default Delete;