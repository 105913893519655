import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import OfflineIcon from '@material-ui/icons/CloudOff';

import { setIsOffline } from '../../redux/actions';

const OfflineView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <Container>
            <Grid 
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title={"Network Connection Issue"} avatar={<Avatar><OfflineIcon /></Avatar>}/>
                        <CardContent>
                            <Typography variant="body2">{ t('common.errors.networkConnectionIssueDetected') }</Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => { dispatch(setIsOffline(false)) }}>{ t('common.buttons.tryAgain') }</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default OfflineView;