import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { listItemStyles, selectedListItemStyles } from './SidebarListItemStyles';

const SelectCompanySection = (props) => {
    const routeIsActive = useRouteMatch({
        path: ['/company/:company_guid/:tab?/:view?', '/selectCompany'],
        strict: true,
        exact: true
    });

    const classes = Boolean(routeIsActive) ? selectedListItemStyles() : listItemStyles();
    const { company } = props;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ListItem
                button
                key='selectCompany'
                className={classes.listItem}
                onClick={props.onClick} >

                <ListItemText
                    primary={ t('sidebar.labels.selectedCompany') }
                    primaryTypographyProps={{ className: classes.primaryText }}
                    secondary={company ? company.name : t('sidebar.buttons.openCompanySelectionPage')}
                    secondaryTypographyProps={{ className: classes.secondaryText }}
                />
            </ListItem>
        </React.Fragment>
    );
}

export default SelectCompanySection;