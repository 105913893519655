import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ObserveIcon from '@material-ui/icons/Visibility';
import StaffIcon from '@material-ui/icons/VerifiedUser';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';

import ActivityStatus from '../../enums/ActivityStatus';
import { useTranslation } from 'react-i18next';

const MainActivityLinks = (props) => {
    const { t } = useTranslation();
    const adminLink = props.activity.links?.adminLink;
    const moderatorLink = props.activity.links?.moderatorLink;
    const staffObserverLink = props.activity.links?.staffObserverLink;

    const status = props.activity.details.detailedStatus;

    const companyRole = props.companyRole;

    // Return a string describing why the moderator link button ('View Content / Edit Activity Content')
    // is disabled.
    const getModeratorLinkText = () => {
        if (!moderatorLink) {
            return t('activityPage.text.notModeratorOfThisActivity');
        } else if (!ActivityStatus[status].canLinkToActivity) {
            return "Setup must be completed";
        } else {
            return null;
        }
    }

    return (
        <List component="nav" aria-label="contacts">
            <ListSubheader>{ t('activityPage.text.activityActionsHeading') }</ListSubheader>

            <Tooltip title={getModeratorLinkText() ?? ""} arrow>
                <div>
                    <ListItem button disabled={Boolean(getModeratorLinkText())} onClick={() => window.open(moderatorLink, '_blank')}>
                        <ListItemIcon>
                            <EditIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ color: "primary" }}
                            primary={
                                [ActivityStatus.running, ActivityStatus.readyToStart]
                                    .includes(ActivityStatus[status]) ? t('activityPage.buttons.viewActivityContent') : t('activityPage.buttons.manageActivityContent')
                            } />
                    </ListItem>
                </div>
            </Tooltip>

            { companyRole.canDeleteActivity &&
                <Tooltip title={ ActivityStatus[status].allowsDeletion ? "" : t('activityPage.tooltips.cannotDeleteRunningActivity') } arrow>
                    <div>
                        <ListItem
                            button
                            disabled={ActivityStatus[status].allowsDeletion === false}
                            onClick={props.onDeleteBtnClick}>
                            <ListItemIcon>
                                <DeleteIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ color: "primary" }}
                                primary={ t('activityPage.buttons.deleteActivity') } />
                        </ListItem>
                    </div>
                </Tooltip>
            }

            { adminLink && ActivityStatus[status].canLinkToActivity &&
                <React.Fragment>
                    <ListSubheader>{ t('common.text.staffToolsForEntityHeading') }</ListSubheader>
                    <ListItem button onClick={() => window.open(adminLink, '_blank') }>
                        <ListItemIcon>
                            <StaffIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ color: "primary" }}
                            primary={ t('activityPage.buttons.viewOrEditActivityContent') } />
                    </ListItem>
                </React.Fragment>
            }

            { staffObserverLink && ActivityStatus[status].canLinkToActivity &&
                <ListItem button onClick={() => window.open(staffObserverLink, '_blank') }>
                    <ListItemIcon>
                        <ObserveIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ color: "primary" }}
                        primary={ t('activityPage.buttons.observeActivity') } />
                </ListItem>
            }
        </List>
    )
};

export default MainActivityLinks;