import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import HistoryIcon from '@material-ui/icons/History';

import SelectableTableRow from '../shared/SelectableTableRow';
import { TableHead, Typography, useTheme, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto'
    },
    flexRow: {
        display: 'flex',
        justifyItems: 'space-between'
    }
});

const CompaniesTableRow = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <SelectableTableRow
            key={props.id}
            hover={true}
            onClick={() => props.onClick(props.company)}
            selected={props.selected}
        >
            <TableCell scope="row">
                { props.lastAccessed ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="body2" display="block">{props.name}</Typography>

                        <Tooltip title={ t('selectCompanyPage.tooltips.recentlyAccessedCompany') } arrow>
                            <HistoryIcon style={{ display: 'inline-block', fill: theme.palette.secondary.main }} />
                        </Tooltip>
                    </div>
                    :
                    <Typography variant="body2">
                        {props.name}
                    </Typography>
                }
            </TableCell>
        </SelectableTableRow>
    )
};

const MemoisedCompaniesTableRow = React.memo(CompaniesTableRow);

const CompaniesTableView = (props) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <TableContainer>
                <Table aria-label="Table of companies">
                    <TableHead style={{ display: 'none' }}>
                        <TableRow>
                            <TableCell>Company</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.companies.map((company, index) => (
                            <MemoisedCompaniesTableRow
                                key={company.id}
                                id={company.id}
                                company={company}
                                onClick={props.handleSelection}
                                selected={props.selectedIndex===index}
                                name={company.name}
                                lastAccessed={company.lastAccessed}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default CompaniesTableView;