// Simple component to allow for 'spacing' in a basic toolbar:
// e.g: [] <ToolbarSpacer> [] <ToolbarSpacer> []
// e.g: [] <------------- ToolbarSpacer (Grow) -------------> [][]
//
// Relies on flexGrow, so for toolbars with more complex
// layouts there may be conflict issues using this class.
//
// Set the 'grow' prop to true for grow behaviour, otherwise fixed size will
// be used.

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    fixed: {
        width: theme.spacing(1)
    },
    fixedLarge: {
        width: theme.spacing(3)
    }
}));

export default (props) => {
    const classes = useStyles();

    const getClassName = () => {
        if (props.grow === true) {
            return classes.grow
        } else if (props.large === true) {
            return classes.fixedLarge;
        } else {
            return classes.fixed;
        }
    };

    return (
        <div className={getClassName()}></div>
    )
};