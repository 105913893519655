import React from 'react';
import { DialogContent, DialogActions, Button, Divider, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import portalApi, { handleError } from '../../../utils/portalApi';
import ResponsiveDialog from '../../shared/ResponsiveDialog';

const FormContent = (props) => {
    const { values, initialValues, setFieldValue } = useFormikContext();
    const availableRespondentIds = props.availableRespondents.map(respondent => respondent.id);

    const handleToggleAll = () => {
        if (values.selectedRespondentIds.length === 0) {
            // Nothing currently selected, so just select all available respondents.
            setFieldValue('selectedRespondentIds', availableRespondentIds);
        } else if(values.selectedRespondentIds.length !== props.availableRespondents.length) {
            setFieldValue('selectedRespondentIds', [...new Set([...values.selectedRespondentIds, ...availableRespondentIds])]);
        } else {
            setFieldValue('selectedRespondentIds', []);
        }
    }

    const handleRespondentToggle = (respondent) => {
        if (values.selectedRespondentIds.includes(respondent.id)) {
            setFieldValue('selectedRespondentIds', values.selectedRespondentIds.filter(val => val !== respondent.id));
        } else {
            setFieldValue('selectedRespondentIds', [respondent.id, ...values.selectedRespondentIds]);
        }
    };

    const getRespondentSelectionStatus = (respondent) => {
        if (initialValues.selectedRespondentIds.includes(respondent.id) && !values.selectedRespondentIds.includes(respondent.id)) {
            return -1;
        }

        if (values.selectedRespondentIds.includes(respondent.id) && !initialValues.selectedRespondentIds.includes(respondent.id)) {
            return 1;
        }

        return 0;
    }

    const getRespondentSelectionStatusIcon = (respondent) => {
        switch (getRespondentSelectionStatus(respondent)) {
        case -1: 
            return (
                <Tooltip arrow title="This user will be removed">
                    <Avatar variant="rounded" style={{ backgroundColor: 'red', width: '1em', height: '1em' }}>
                        <RemoveIcon style={{ width: '90%', height: 'auto' }} />
                    </Avatar>
                </Tooltip>
            );
        case 1:
            return (
                <Tooltip arrow title="This user will be added">
                    <Avatar variant="rounded" style={{ backgroundColor: 'green', width: '1em', height: '1em' }}>
                        <AddIcon style={{ width: '90%', height: 'auto' }} />
                    </Avatar>
                </Tooltip>
            )
        default:
            return null;
        }
    }

    const pseudonymColumnIsVisible = props.availableRespondents.find(respondent => respondent.pseudonym);
    const timezoneColumnIsVisible = props.availableRespondents.find(respondent => respondent.timezone);

    return(
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                edge="start"
                                checked={values.selectedRespondentIds.length === props.availableRespondents.length}
                                tabIndex={-1}
                                disableRipple
                                color="primary"
                                onClick={handleToggleAll}
                            />
                        </TableCell>
                        <TableCell>Name</TableCell>
                        { pseudonymColumnIsVisible && <TableCell>Pseudonym</TableCell> }
                        <TableCell>Email</TableCell>
                        { timezoneColumnIsVisible && <TableCell>Timezone</TableCell> }
                        <TableCell style={{ minWidth: '4em' }}>&nbsp; { /* Change indicator icon */ } </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.availableRespondents.map(respondent => {
                            return(
                                <TableRow 
                                    key={respondent.id} 
                                    selected={values.selectedRespondentIds.includes(respondent.id)}
                                    onClick={() => handleRespondentToggle(respondent) }
                                    hover
                                    style={{ cursor: "pointer" }}>
                                    <TableCell>
                                        <Checkbox
                                            edge="start"
                                            checked={values.selectedRespondentIds.includes(respondent.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                            
                                        />
                                    </TableCell>
                                    <TableCell>{ `${respondent.firstName} ${respondent.lastName}` }</TableCell>
                                    { pseudonymColumnIsVisible && <TableCell>{ respondent.pseudonym }</TableCell> }
                                    <TableCell>{ respondent.email }</TableCell>
                                    { timezoneColumnIsVisible && <TableCell>{ respondent.timezone }</TableCell> }
                                    <TableCell>{ getRespondentSelectionStatusIcon(respondent) }</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

const FormActions = (props) => {
    const { handleSubmit, dirty, isSubmitting } = useFormikContext();

    return (
        <DialogActions>
            <Button size="large" onClick={props.onCancel}>
                Cancel
            </Button>
            <Button 
                variant="contained" 
                size="large"
                onClick={handleSubmit} 
                color="primary" 
                disabled={isSubmitting || !dirty}>
                Save Changes
            </Button>
        </DialogActions>
    )
}

const EditRespondentsModal = (props) => {
    const activityId = props.activity.details.activityId;
    const availableRespondents = props.activity.respondents.available;

    const handleSubmit = (values, actions) => {       
        portalApi
            .put(`/api/activity/${activityId}/respondents`, {
                available: [],
                participants: values.selectedRespondentIds
            })
            .then(response => {
                props.onSave();
                actions.setSubmitting(false);
            })
            .catch(handleError);
    };

    return (
        <Formik
            initialValues={{
                selectedRespondentIds: props.activity.respondents.participants
            }}
            onSubmit={handleSubmit}
        >
            <ResponsiveDialog heading={"Select Respondents"} open={props.open} onCancel={props.onCancel}>
                <DialogContent style={{ backgroundColor: '#f5f5f5', paddingTop: '1em', paddingBottom: '2em' }}>
                    <FormContent availableRespondents={availableRespondents} />
                </DialogContent>

                <Divider />              
                <FormActions onCancel={props.onCancel} />
            </ResponsiveDialog>
        </Formik>
    );
}

export default EditRespondentsModal;
