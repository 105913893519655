import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { Typography, IconButton, Popover } from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TimezonePreviewer from './TimezonePreviewer';

const dateFormat = { month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false };
const dateFormatWithYear = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false };

const LocalisableDate = (props) => {
    const { i18n } = useTranslation();
    const selectedTimezone = useSelector(state => state.UserDetailsReducer?.userDetails?.ianaTimezoneId);
    const [anchorEl, setAnchorEl] = useState(null);
    const typographyProps = { variant: 'body1', noWrap: true, ...props.typographyProps };

    const localTimezone = DateTime.local().zoneName;
    const theDate = DateTime
        .fromISO(props.date, { locale: i18n.language })
        .setZone(selectedTimezone ?? 'local');

    const timezonesDiffer = () => {
        return theDate.zoneName !== localTimezone;
    }

    const renderWithDate = () => (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                <Typography { ...typographyProps }>
                    { timezonesDiffer()
                        ? theDate.toLocaleString(DateTime.DATETIME_FULL)
                        : theDate.toLocaleString(
                            theDate.year === DateTime.local().year
                                ? dateFormat
                                : dateFormatWithYear
                        ) }
                </Typography>

                <IconButton onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorEl(e.currentTarget.parentElement)
                }}>
                    { timezonesDiffer()
                        ? <HelpIcon style={{ pointerEvents: 'none' }} />
                        : <AccessTimeIcon style={{ pointerEvents: 'none' }} /> }
                </IconButton>
            </div>

            <Popover
                modifiers={{ arrow: { enabled: true }}}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => { setAnchorEl(null); }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClick={(e) => {
                    // Needed to handle click events properly when being shown in
                    // the table views.
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <TimezonePreviewer referenceDate={theDate} />
            </Popover>
        </div>
    )

    return (
        props.date ? renderWithDate() : <Typography variant="body1">...</Typography>
    )
};

LocalisableDate.propTypes = {
    date: PropTypes.string,
    typographyProps: PropTypes.object
}

export default LocalisableDate;