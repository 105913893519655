import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import api, { handleError } from '../../../utils/portalApi';

const DetailedTimezone = (props) => {
    const { i18n } = useTranslation();
    const [tzDisplayName, setTzDisplayName] = useState(null);

    const currentLanguage = props.language ?? i18n.language;
    const timezone = props.timezone ?? DateTime.local().zoneName;

    const date = DateTime
        .fromISO(props.date, { locale: currentLanguage })
        .setZone(timezone);

    useEffect(() => {
        api
            .get(`api/culture/iana/timezoneDisplayName?timezoneId=${timezone}&languageCode=${currentLanguage}`)
            .then((response) => setTzDisplayName(response.data))
            .catch(handleError);
    }, [currentLanguage, timezone]);

    return (
        <React.Fragment>
            <Typography variant="body1" display="block">
                { date.toFormat('ZZZZZ') }
            </Typography>

            <Typography variant="body2" display="block" style={{ color: '#666' }} gutterBottom>
                { tzDisplayName }
            </Typography>
        </React.Fragment>
    );
}

DetailedTimezone.propTypes = {
    date: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    language: PropTypes.string,
}

export default DetailedTimezone;