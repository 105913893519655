import { combineReducers, createStore } from 'redux';
import reducers from './reducers';

// Allows the store to be mocked for testing etc. 
// You don't need to pass in initialState covering all reducers, allowing the
// defaults to be used as normal when needed. 
export const createMockStore = (initialState) => {
    return createStore(
        combineReducers(reducers), 
        initialState, 
        window.__REDUX_DEVTOOLS_EXTENSION__?.()
    );
}

// Always export default store
const store = createStore(combineReducers(reducers), window.__REDUX_DEVTOOLS_EXTENSION__?.());
export default store;
