import React, { useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import BackIcon from '@material-ui/icons/ArrowBack';

import PortalIFrame from '../shared/PortalIFrame';

const Reports = (props) => {
    // Used to track when user navigates within iframe (e.g. to a specific activity report)
    // If set, we can assume that the user is no longer on the reports homepage.
    const startUrl = `/xx/${props.projectId}/Report/List`;
    
    // Increment the frameId key to force the iframe to reload from
    // scratch. Easiest way to return back to the opening page as it's non-trivial to
    // get a reference to the iframe element. 
    const [iframeId, setiFrameId] = useState(0);
    const [portalUrlHasChanged, setPortalUrlHasChanged] = useState(false);

    return (
        <React.Fragment>
            <Collapse in={portalUrlHasChanged}>
                <Container>
                    <Button 
                        onClick={() => {
                            setiFrameId(iframeId + 1);
                            setPortalUrlHasChanged(false);
                        }}
                        startIcon={<BackIcon/>}
                    >
                        All Reports
                    </Button>
                </Container>
            </Collapse>

            <PortalIFrame
                id={'projectReportsIframe'}
                key={iframeId}
                relativeUrl={startUrl}
                redirectUrl={props.redirectUrl} 
                onUrlChange={(url, event) => {
                    if (url.indexOf(startUrl) === -1) {
                        setPortalUrlHasChanged(true);
                    }
                }}
            />
        </React.Fragment>
    )
}

export default Reports;