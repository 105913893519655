import React from 'react';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import OverviewListItem from '../shared/OverviewListItem';

const BasicInfo = (props) => {
    const { t } = useTranslation();
    const { name, client } = props.project;

    return (
        <List>
            <OverviewListItem title={ t('common.labels.entityName') } value={ name } />
            <Divider />

            <OverviewListItem title={ t('projectPage.labels.clientName') } value={ client } />
        </List>
    );
};

export default BasicInfo;