import React from 'react';
import { DialogContent, DialogActions, Button, Divider, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import portalApi, { handleError } from '../../../utils/portalApi';
import ResponsiveDialog from '../../shared/ResponsiveDialog';

const FormContent = (props) => {
    const { values, initialValues, setFieldValue } = useFormikContext();
    const availableModeratorIds = props.availableModerators.map(moderator => moderator.id);

    const handleToggleAll = () => {
        if (values.selectedModeratorIds.length === 0) {
            // Nothing currently selected, so just select all available moderators.
            setFieldValue('selectedModeratorIds', availableModeratorIds);
        } else if(values.selectedModeratorIds.length !== props.availableModerators.length) {
            setFieldValue('selectedModeratorIds', [...new Set([...values.selectedModeratorIds, ...availableModeratorIds])]);
        } else {
            setFieldValue('selectedModeratorIds', []);
        }
    }

    const handleModeratorToggle = (moderator) => {
        if (values.selectedModeratorIds.includes(moderator.id)) {
            setFieldValue('selectedModeratorIds', values.selectedModeratorIds.filter(val => val !== moderator.id));
        } else {
            setFieldValue('selectedModeratorIds', [moderator.id, ...values.selectedModeratorIds]);
        }
    };

    const getModeratorSelectionStatus = (moderator) => {
        if (initialValues.selectedModeratorIds.includes(moderator.id) && !values.selectedModeratorIds.includes(moderator.id)) {
            return -1;
        }

        if (values.selectedModeratorIds.includes(moderator.id) && !initialValues.selectedModeratorIds.includes(moderator.id)) {
            return 1;
        }

        return 0;
    }

    const getModeratorSelectionStatusIcon = (moderator) => {
        switch (getModeratorSelectionStatus(moderator)) {
        case -1: 
            return (
                <Tooltip arrow title="This user will be removed">
                    <Avatar variant="rounded" style={{ backgroundColor: 'red', width: '1em', height: '1em' }}>
                        <RemoveIcon style={{ width: '90%', height: 'auto' }} />
                    </Avatar>
                </Tooltip>
            );
        case 1:
            return (
                <Tooltip arrow title="This user will be added">
                    <Avatar variant="rounded" style={{ backgroundColor: 'green', width: '1em', height: '1em' }}>
                        <AddIcon style={{ width: '90%', height: 'auto' }} />
                    </Avatar>
                </Tooltip>
            )
        default:
            return null;
        }
    }

    const pseudonymColumnIsVisible = props.availableModerators.find(moderator => moderator.pseudonym);
    const timezoneColumnIsVisible = props.availableModerators.find(moderator => moderator.timezone);

    return(
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                edge="start"
                                checked={values.selectedModeratorIds.length === props.availableModerators.length}
                                tabIndex={-1}
                                disableRipple
                                color="primary"
                                onClick={handleToggleAll}
                            />
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        { pseudonymColumnIsVisible && <TableCell>Pseudonym</TableCell> }
                        { timezoneColumnIsVisible && <TableCell>Timezone</TableCell> }
                        <TableCell style={{ minWidth: '4em' }}>&nbsp; { /* Change indicator icon */ } </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.availableModerators.map(moderator => {
                            return(
                                <TableRow 
                                    key={moderator.id} 
                                    selected={values.selectedModeratorIds.includes(moderator.id)}
                                    onClick={() => handleModeratorToggle(moderator) }
                                    hover
                                    style={{ cursor: "pointer" }}>
                                    <TableCell>
                                        <Checkbox
                                            edge="start"
                                            checked={values.selectedModeratorIds.includes(moderator.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                            
                                        />
                                    </TableCell>
                                    <TableCell>{ `${moderator.firstName} ${moderator.lastName}` }</TableCell>
                                    <TableCell>{ moderator.email }</TableCell>
                                    { pseudonymColumnIsVisible && <TableCell>{ moderator.pseudonym }</TableCell> }
                                    { timezoneColumnIsVisible && <TableCell>{ moderator.timezone }</TableCell> }
                                    <TableCell>{ getModeratorSelectionStatusIcon(moderator) }</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

const FormActions = (props) => {
    const { handleSubmit, dirty, isSubmitting } = useFormikContext();

    return (
        <DialogActions>
            <Button size="large" onClick={props.onCancel}>
                Cancel
            </Button>
            <Button 
                variant="contained" 
                size="large"
                onClick={handleSubmit} 
                color="primary" 
                disabled={isSubmitting || !dirty}>
                Save Changes
            </Button>
        </DialogActions>
    )
}

const EditModeratorsModal = (props) => {
    const activityId = props.activity.details.activityId;
    const availableModerators = props.activity.moderators.available;

    const handleSubmit = (values, actions) => {       
        portalApi
            .put(`/api/activity/${activityId}/moderators`, {
                available: [],
                participants: values.selectedModeratorIds
            })
            .then(response => {
                props.onSave();
                actions.setSubmitting(false);
            })
            .catch(handleError);
    };

    return (
        <Formik
            initialValues={{
                selectedModeratorIds: props.activity.moderators.participants
            }}
            onSubmit={handleSubmit}
        >
            <ResponsiveDialog heading={"Select Moderators"} open={props.open} onCancel={props.onCancel}>
                <DialogContent style={{ backgroundColor: '#f5f5f5', paddingTop: '1em', paddingBottom: '2em' }}>
                    <FormContent availableModerators={availableModerators} />
                </DialogContent>

                <Divider />              
                <FormActions onCancel={props.onCancel} />
            </ResponsiveDialog>
        </Formik>
    );
}

export default EditModeratorsModal;
