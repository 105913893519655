import {
    SET_USER_DETAILS,
    SET_SELECTED_COMPANY,
    SET_SELECTED_PROJECT,
    SET_SELECTED_ACTIVITY,
    SET_IS_MENU_OPEN,
    SET_WAITING_SERVICE_WORKER,
    SET_IS_OFFLINE,
    SET_THEME,
    ADD_PREVIEW_TIMEZONE,
    REMOVE_PREVIEW_TIMEZONE,
    SET_USER_TIMEZONE,
    SET_COMPANY_COUNT
} from './actions'
import defaultTheme from '../themes/default';

const UserDetailsReducer = (state = null, action) => {
    switch (action.type) {
    case SET_USER_DETAILS:
        return { ...state, userDetails: action.userDetails };
    case SET_USER_TIMEZONE:
        return { ...state, userDetails: { ...state.userDetails, ianaTimezoneId: action.timezone }}
    case SET_COMPANY_COUNT:
        return { ...state, companyCount: action.companyCount }
    default:
        return state
    }
}

const SelectedCompanyReducer = (state = null, action) => {
    switch (action.type) {
    case SET_SELECTED_COMPANY:
        return { ...state, selectedCompany: action.company };
    default:
        return state;
    }
}

const SelectedProjectReducer = (state = null, action) => {
    switch (action.type) {
    case SET_SELECTED_PROJECT:
        return { ...state, selectedProject: action.project };
    default:
        return state;
    }
}

const SelectedActivityReducer = (state = null, action) => {
    switch (action.type) {
    case SET_SELECTED_ACTIVITY:
        return { ...state, selectedActivity: action.activity };
    default:
        return state;
    }
}

const defaultUIState = {
    menu: {
        isOpen: false
    },
    waitingServiceWorker: null,
    isOffline: false,
    theme: defaultTheme,
    previewTimezones: []
}

const UserInterfaceStateReducer = (state = defaultUIState, action) => {
    switch (action.type) {
    case SET_IS_MENU_OPEN:
        return {
            ...state,
            menu: {
                isOpen: action.isOpen
            }
        }
    case SET_WAITING_SERVICE_WORKER:
        return {
            ...state,
            waitingServiceWorker: action.waitingServiceWorker
        }
    case SET_IS_OFFLINE:
        return {
            ...state,
            isOffline: action.isOffline
        }
    case SET_THEME:
        return {
            ...state,
            theme: action.theme
        }
    case ADD_PREVIEW_TIMEZONE:
        return {
            ...state,
            previewTimezones: [...state.previewTimezones, action.timezone]
        }
    case REMOVE_PREVIEW_TIMEZONE:
        return {
            ...state,
            previewTimezones: state.previewTimezones.filter((tz) => tz !== action.timezone)
        }
    default:
        return state;
    }
}

export default {
    UserDetailsReducer,
    SelectedCompanyReducer,
    SelectedProjectReducer,
    SelectedActivityReducer,
    UserInterfaceStateReducer
};