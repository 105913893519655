
import { makeStyles } from "@material-ui/core";

export const listItemStyles = makeStyles(theme => ({
    listItem: {
        backgroundColor: theme.sidebar.buttonBackgroundColor,
        '&:hover': {
            backgroundColor: theme.sidebar.buttonBackgroundColor_hover
        },
        borderRadius: '0.5em',
        marginBottom: '0.5em'
    },
    primaryText: {
        ...theme.typography.caption,
        color: theme.sidebar.buttonColor
    },
    secondaryText: {
        ...theme.typography.h5,
        fontSize: 16,
        color: theme.sidebar.buttonColor
    }
}));

export const selectedListItemStyles = makeStyles(theme => ({
    listItem: {
        backgroundColor: theme.sidebar.selectedButtonBackgroundColor,
        '&:hover': {
            backgroundColor: theme.sidebar.selectedButtonBackgroundColor_hover
        },
        borderRadius: '0.5em'
    },
    primaryText: {
        ...theme.typography.caption,
        color: theme.sidebar.selectedButtonColor,
        fontWeight: '500'
    },
    secondaryText: {
        ...theme.typography.h5,
        fontSize: 16,
        color: theme.sidebar.selectedButtonColor,
        fontWeight: '500'
    }
}));
