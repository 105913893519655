import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import SelectableTableRow from '../../shared/SelectableTableRow';
import ProjectStatusList from './StatusList';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    }
});

const ProjectsTableView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    // Determines whether any of the projects require 'misc' info, that is
    // whether they have running or upcoming activities. Or are archived.
    const requiresMiscInfoColumn = props.projects.find(p =>
        p.runningActivitiesCount ||
        p.upcomingActivitiesCount ||
        p.isArchived
    )

    return (
        <Paper className={classes.root} variant="outlined">
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{ t('companyPage.labels.projectNameAndClient') }</TableCell>
                        <Hidden smDown>
                            <TableCell>{ t('common.labels.entityDescription') }</TableCell>
                        </Hidden>
                        {requiresMiscInfoColumn && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.projects.map((project, index) => (
                        <SelectableTableRow
                            key={project.id}
                            hover={true}
                            onClick={() => props.onProjectSelected(project)}
                            selected={props.selectedItemIndex === index}
                        >
                            <TableCell component="th" scope="row" style={{ width: '20em' }}>
                                <Typography
                                    variant="subtitle2"
                                    color={ project.isArchived ? "inherit" : "primary" }
                                >
                                    {project.name}
                                </Typography>
                                <Typography variant="caption">{project.client}</Typography>
                            </TableCell>

                            <Hidden smDown>
                                <TableCell>
                                    <Typography variant="body2">
                                        {project.description || <i>...</i>}
                                    </Typography>
                                </TableCell>
                            </Hidden>

                            {requiresMiscInfoColumn &&
                                <TableCell style={{ width: '17em' }}>
                                    <ProjectStatusList project={project} />
                                </TableCell>
                            }
                        </SelectableTableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default ProjectsTableView;