import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Redirect,
    BrowserRouter as Router,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

import { create } from 'jss';
import rtl from 'jss-rtl';

// State
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyCount, setUserDetails } from './redux/actions';
import { useUserDetails } from './redux/hooks';

// MaterialUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles, StylesProvider, jssPreset } from '@material-ui/core/styles';

// Theming (Material-UI)
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// Components
import Sidebar from './components/Sidebar/Sidebar';
import LoadingPlaceholder from './components/shared/LoadingPlaceholder';

// Views
import SelectCompany from './components/SelectCompany/SelectCompany';
import AccountSettings from './components/user/AccountSettings';
import OfflineView from './components/offline/OfflineView';
import CompanyOverview from './components/company/Overview';
import ProjectOverview from './components/project/Overview';
import ActivityOverview from './components/activity/Overview';
import AboutHome from './components/about/Home';

// Utils
import portalApi, { handleError } from './utils/portalApi';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        maxWidth: '100%',
        paddingBottom: theme.spacing(4),
        height: '100vh'
    }
}));

const App = () => {
    const classes = useStyles();
    const [sidebarIsOpen, setSidebarIsOpen] = React.useState(false);
    const dispatch = useDispatch();
    const userDetails = useUserDetails();
    const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
    const [isUpdatingApp, setIsUpdatingApp] = useState(false);
    const isOffline = useSelector(state => state.UserInterfaceStateReducer.isOffline);

    const currentTheme = useSelector(state => state.UserInterfaceStateReducer.theme);
    const theme = currentTheme;

    // Prepare for rtl themes if necessary.
    const { i18n, t } = useTranslation();

    // Set Yup locale so that error messages are translated.
    setLocale({
        // use constant translation keys for messages without values
        mixed: {
            default: 'field_invalid',
            required: t('common.errors.thisFieldIsRequired')
        },
        // use functions to generate an error object that includes the value from the schema
        number: {
            min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
            max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
        },
    });

    theme.direction = i18n.dir();
    document.getElementsByTagName('body')[0].setAttribute('dir', i18n.dir());

    const jssPlugins = [...jssPreset().plugins];
    if (i18n.dir() === 'rtl') {
        jssPlugins.push(rtl());
    }
    const jss = create({ plugins: jssPlugins });

    useEffect(() => {
        if (waitingServiceWorker) {
            console.log(waitingServiceWorker);
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }, [waitingServiceWorker]);

    useEffect(() => {
        portalApi
            .get('/api/currentuser/details')
            .then(response => dispatch(setUserDetails(response.data)))
            .catch(handleError);

        portalApi
            .get('/api/companies')
            .then(response => {
                dispatch(setCompanyCount(response.data.length));
            })
            .catch(handleError);
    }, [dispatch]);

    const handleAppUpdate = () => {
        setIsUpdatingApp(true);
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }

    const handleDrawerToggle = () => {
        setSidebarIsOpen(!sidebarIsOpen);
    };

    const routes = () => (
        <Switch>
            <Route path="/company/:company_guid/project/:project_guid/activity/:activity_guid/:tab?" component={ActivityOverview} />
            <Route path="/company/:company_guid/project/:project_guid/:tab?/:view?" component={ProjectOverview} />
            <Route path="/company/:company_guid/:tab?/:view?" component={CompanyOverview} />
            <Route path="/selectCompany" component={SelectCompany} />
            <Route path="/currentUser/accountSettings" component={AccountSettings} />
            <Route path="/about" component={AboutHome} />

            <Route path="/" exact>
                <Redirect from="/" to="/selectCompany" />
            </Route>
        </Switch>
    );

    const offline = () => {
        return isOffline ? <OfflineView /> : null;
    }

    const loading = () => {
        return !userDetails ? <LoadingPlaceholder /> : null;
    }

    const mainContent = () => (
        <div className={classes.root}>
            <Router>
                <Sidebar isOpen={sidebarIsOpen} onClose={handleDrawerToggle} />

                <main className={classes.content}>
                    {waitingServiceWorker &&
                        <Dialog
                            disableBackdropClick
                            disableEscapeKeyDown
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Update Available"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    { t('common.text.newAppVersionAvailable') }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleAppUpdate} color="primary" disabled={isUpdatingApp}>
                                    { t('common.buttons.updateNow') }
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }

                    { loading() || routes() }
                </main>
            </Router>
        </div>
    );

    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                { offline() || mainContent() }
            </ThemeProvider>
        </StylesProvider>
    );
}

export default App;
