import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery, Dialog, Slide, useTheme, DialogTitle, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ResponsiveDialog = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <Dialog {...props} fullScreen={isMobile} TransitionComponent={Transition} maxWidth={'xl'}>
            <Toolbar 
                style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    paddingRight: '1em'
                }}
                disableGutters
            >
                <DialogTitle><Typography variant="overline">{ props.heading }</Typography></DialogTitle>
                <IconButton color="inherit" onClick={props.onCancel}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>

            { props.children }            
        </Dialog>
    )
}

ResponsiveDialog.propTypes = {
    heading: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default ResponsiveDialog;
