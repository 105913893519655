import React, { useEffect, useState, useCallback } from 'react';
import { useSelectedCompany, useSelectedProject, useSelectedActivity } from '../../redux/hooks';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import SettingsIcon from '@material-ui/icons/Settings';
import OverviewIcon from '@material-ui/icons/Dashboard';
import LinksIcon from '@material-ui/icons/Link';
import ReportsIcon from '@material-ui/icons/Description';

import TopBar from '../shared/TopBar';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import portalApi, { handleError } from '../../utils/portalApi';
import { setSelectedCompany, setSelectedProject, setSelectedActivity } from '../../redux/actions';
import TopBarTabs from '../shared/TopBarTabs';
import PortalIFrame from '../shared/PortalIFrame';
import Delete from './Delete';
import {
    createSetupRelativeURL,
    createRescheduleRelativeURL,
    createLinksRelativeURL,
    createReportsRelativeURL,
} from './activityUrlHelper';
import ProductType from '../../enums/ProductType';
import ActivityStatus from '../../enums/ActivityStatus';
import CompanyRole from '../../enums/CompanyRole';
import OverviewTab from './OverviewTab';
import SetupActivity from './SetupActivity/SetupActivity';
import { FlexHeightContainer, FlexHeightInnerContainer } from '../shared/LayoutComponents';

const getTabs = (t, companyRole) => {
    return [
        {
            key: 'overview',
            label: t('activityPage.tabs.activityOverview'),
            icon: <OverviewIcon />,
            isEnabled: true
        },
        {
            key: 'setup',
            label: t('activityPage.tabs.setupActivity'),
            icon: <SettingsIcon />,
            isEnabled: companyRole?.canSetupActivity
        },
        {
            key: 'links',
            label: t('activityPage.tabs.viewActivityLinks'),
            icon: <LinksIcon />,
            isEnabled: companyRole?.canViewActivityLinks
        },
        {
            key: 'reports',
            label: t('activityPage.tabs.viewActivityReports'),
            icon: <ReportsIcon />,
            isEnabled: companyRole?.canViewActivityReports
        },
    ]
};

const Overview = ({ match }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const companyId = match.params.company_guid;
    const projectId = match.params.project_guid;
    const activityId = match.params.activity_guid;

    const selectedCompany = useSelectedCompany();
    const selectedProject = useSelectedProject();
    const selectedActivity = useSelectedActivity();
    const companyRole = CompanyRole[selectedCompany?.role];

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    const tabs = getTabs(t, companyRole).filter(tab => tab.isEnabled);

    const pageRoot = `/company/${companyId}/project/${projectId}/activity/${activityId}`;

    const loadActivity = useCallback(() => {
        let source = portalApi.CancelToken.source();

        const getCompany = portalApi.get(`/api/companies/${companyId}`);
        const getProject = portalApi.get(`/api/projects/${projectId}`);
        const getActivity = portalApi.get(`/api/activity/${activityId}`);

        // Execute getCompany/Project/Activity requests at the same time to avoid
        // race condition.
        Axios
            .all([getCompany, getProject, getActivity])
            .then(Axios.spread((...responses) => {
                dispatch(setSelectedCompany(responses[0].data));
                dispatch(setSelectedProject(responses[1].data));
                dispatch(setSelectedActivity(responses[2].data));
            }))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, [companyId, projectId, activityId, dispatch]);

    useEffect(() => {
        loadActivity();
    }, [loadActivity]);

    const loading = () => (
        selectedCompany && selectedProject && selectedActivity ? null : <LoadingPlaceholder />
    )

    const routes = () => {
        const productType = selectedActivity?.details?.activityType;
        const activityStatus = selectedActivity?.details?.detailedStatus;

        return (
            <React.Fragment>
                <Route path={`${pageRoot}/overview`}>
                    <OverviewTab
                        activity={selectedActivity}
                        companyRole={companyRole}
                        companyId={companyId}
                        projectId={projectId}
                        activityId={activityId}
                        pageRoot={pageRoot} />
                </Route>

                <Route path={`${pageRoot}/setup`} exact>
                    {
                        productType !== 'bulletinBoard' ?
                            <PortalIFrame
                                containerProps={{ disableGutters: true }}
                                id={'activitySetupIframe'}
                                relativeUrl={createSetupRelativeURL(ProductType[productType], projectId, activityId)}
                                redirectUrl={`${pageRoot}/overview`} />
                            :
                            <SetupActivity activity={selectedActivity} onSave={loadActivity} />
                    }
                </Route>

                <Route path={`${pageRoot}/setup/reschedule`}>
                    <PortalIFrame
                        containerProps={{ disableGutters: true }}
                        id={'activityRescheduleIframe'}
                        relativeUrl={createRescheduleRelativeURL(ProductType[productType], projectId, activityId)}
                        redirectUrl={`${pageRoot}/overview`} />
                </Route>

                <Route path={`${pageRoot}/links`}>
                    { ActivityStatus[activityStatus].shouldShowLinks || CompanyRole[selectedCompany?.role].canAlwaysViewActivityLinks ?
                        <PortalIFrame
                            containerProps={{ disableGutters: true }}
                            id={'activityLinksIframe'}
                            relativeUrl={createLinksRelativeURL(ProductType[productType], projectId, activityId)}
                            redirectUrl={`${pageRoot}/overview`} />
                        :
                        <div>Links are only visible to non-staff users for activities that are: "Running", "Ready To Start", "Upcoming" or "Cannot Start"</div>
                    }
                </Route>

                <Route path={`${pageRoot}/reports`}>
                    { ActivityStatus[activityStatus].shouldShowReports ?
                        <PortalIFrame
                            containerProps={{ disableGutters: true }}
                            id={'activityReportsIframe'}
                            relativeUrl={createReportsRelativeURL(ProductType[productType], projectId, activityId)}
                            redirectUrl={`${pageRoot}/overview`} />
                        :
                        <div>Reports are only available for Completed activities.</div>
                    }
                </Route>

                <Route path={pageRoot} exact>
                    <Redirect from={pageRoot} to={`${pageRoot}/overview`} exact />
                </Route>
            </React.Fragment>
        );
    }

    const main = () => (
        <FlexHeightContainer>
            <TopBar
                breadcrumbs={[
                    {
                        link: `/company/${selectedCompany.id}`,
                        title: selectedCompany.name
                    },
                    {
                        link: `/company/${selectedCompany.id}/project/${selectedProject.id}/activities`,
                        title: selectedProject.name
                    },
                    {
                        title: selectedActivity.details.description
                    }
                ]}
            >
                <Container>
                    <TopBarTabs
                        value={Math.max(tabs.findIndex((tab) => tab.key === match.params.tab), 0)}
                        onChange={(event, newValue) => {
                            history.push(`${pageRoot}/${tabs[newValue].key}`);
                        }}
                        variant="scrollable"
                    >
                        { tabs.map(tab =>
                            <Tab key={tab.key} label={tab.label} />
                        )}
                    </TopBarTabs>
                </Container>
            </TopBar>

            <FlexHeightInnerContainer>
                <Container>
                    { routes() }
                </Container>
            </FlexHeightInnerContainer>

            <Delete
                open={deleteDialogIsOpen}
                activityId={activityId}
                projectId={projectId}
                onCancel={() => setDeleteDialogIsOpen(false)}
                onSuccess={() => {
                    dispatch(setSelectedActivity(null));
                    history.push(`/company/${companyId}/project/${projectId}/activities`)
                }} />
        </FlexHeightContainer>
    )

    return (
        loading() || main()
    );
};

export default Overview;