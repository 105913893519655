import React, { useState, useEffect } from 'react';
import ResponsiveDialog from '../../shared/ResponsiveDialog';
import { DialogContent, DialogActions, Button, Divider, Typography, IconButton, Container, FormControl, TextField, makeStyles, FormHelperText, InputLabel, Select, MenuItem } from '@material-ui/core';
import ActivityTypeSelector from '../../ActivityTypeSelector/ActivityTypeSelector';
import ActivityAvatar from '../../shared/ActivityAvatar';
import ToolbarSpacer from '../../shared/ToolbarSpacer';
import * as Yup from 'yup';

import EditIcon from '@material-ui/icons/Edit';
import { Formik, useFormikContext } from 'formik';
import { motion, AnimatePresence } from 'framer-motion';
import ProductType from '../../../enums/ProductType';
import { useTranslation } from 'react-i18next';
import portalApi, { handleError } from '../../../utils/portalApi';
import PortalIFrame from '../../shared/PortalIFrame';

const useStyles = makeStyles(theme => ({
    control: {
        display: 'block',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    }
}));

const CreateActivityForm = (props) => {
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string(),
        languageId: Yup.string().required()
    });

    const handleFormSubmission = ((formData, form) => {
        let source = portalApi.CancelToken.source();

        // TODO: This endpoint doesn't exist yet. 
        portalApi
            .post('/api/activities', {
                activityType: formData.type,
                title: formData.title,
                description: formData.description,
                languageId: formData.languageId,
                projectId: formData.projectId
            })
            .then((response) => {
                props.onSuccess(response.data);
                form.resetForm();
            })
            .catch(handleError)
            .finally(() => form.setSubmitting(false));

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    });

    return (
        <Formik
            initialValues={{
                type: '',
                projectId: props.projectId,
                title: '',
                description: '',
                languageId: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
        >
            { props.children }
        </Formik>
    );
}

const CreateActivityModalActions = (props) => {
    const { values, handleSubmit, isSubmitting, dirty } = useFormikContext();

    if (values.type !== "bulletinBoard") {
        return null;
    }

    return (
        <React.Fragment>
            <Divider />
            <DialogActions>
                <Button size="large" onClick={props.onCancel}>Cancel</Button>
                
                <Button 
                    size="large"
                    variant="contained" 
                    color="primary" 
                    onClick={handleSubmit}
                    disabled={isSubmitting || !dirty}>Create</Button>
            </DialogActions>
        </React.Fragment>
    )
}

const FormContent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { values, setFieldValue, errors, touched, handleChange, handleBlur } = useFormikContext();
    const productType = ProductType[values.type];

    if (values.type && values.type !== "bulletinBoard") {
        return (
            <PortalIFrame
                containerProps={{ disableGutters: true }}
                id={'createActivityIFrame'}
                relativeUrl={`/xx/${values.projectId}${productType.portalCreateURL}`}
                onDashboardRedirect={() => {
                    props.onCancel();
                }}
            />
        )
    }

    return (
        <React.Fragment>
            <AnimatePresence exitBeforeEnter>
                { Boolean(values.type) === false &&
                    <motion.div
                        key={"activityTypeSelectorAnimation"}
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, scaleY: 0, height: 0, width: 300 }}
                        transition={{ duration: 0.3 }}
                    >
                        <ActivityTypeSelector onSelect={(product) => {
                            setFieldValue('type', product.productType.enumKey);
                        }} />
                    </motion.div>
                }

                { Boolean(values.type) &&
                    <motion.div
                        key={"activityFormAnimation"}
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, scaleY: 0, height: 0, width: 300 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div style={{ 
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <ActivityAvatar 
                                productType={values.type} 
                                backgroundColor={ProductType[values.type].iconColor}
                            />
                            <ToolbarSpacer large />
                            <Typography variant='h6'>{ t(`productTypes.text.${values.type}`) }</Typography>
                            <ToolbarSpacer grow />

                            <IconButton onClick={() => setFieldValue("type", "")}>
                                <EditIcon />
                            </IconButton>
                        </div>

                        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
                        
                        <FormControl className={classes.control}>
                            <TextField
                                variant="filled"
                                required
                                error={errors?.title && touched?.title}
                                helperText={errors?.title}
                                id="title"
                                label={ 'Activity Title' }
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                        </FormControl>

                        <FormControl variant="filled">
                            <TextField
                                style={{ width: '30em' }}
                                variant="filled"
                                required
                                error={errors?.description && touched?.description}
                                helperText={errors?.description}
                                id="description"
                                label={ 'Private Description' }
                                value={values.description}
                                onChange={handleChange}
                                multiline
                                rows="3" />
                            <FormHelperText>Only visible to Moderators/Observers</FormHelperText>
                        </FormControl>

                        <br/> <br/>

                        <FormControl variant="filled" style={{ width: '15em' }}>
                            <InputLabel id="language-label">Language</InputLabel>
                            <Select
                                labelId="language-label"
                                id="languageId"
                                name="languageId"
                                value={values.languageId}
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>Choose Language</em>
                                </MenuItem>
                                
                                { 
                                    props.languages?.map((language) => 
                                        <MenuItem key={language.id} value={language.id}>{language.displayName}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>

                    </motion.div>
                }
            </AnimatePresence>
        </React.Fragment>
    );
}

const CreateActivityModal = (props) => {
    const [languages, setLanguages] = useState(null);

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        portalApi
            .get(`/api/culture/languages`)
            .then(response => setLanguages(response.data))
            .catch(handleError);

        return () => {
            source.cancel("Cancelled in useEffect cleanup");
        }
    }, []);

    if (props.open === false) {
        // We don't display anything at all when marked as !open. 
        // Note that this also conveniently resets the state of the form when the dialog is dismissed.
        return null;
    } else {
        return(
            <CreateActivityForm projectId={props.projectId} onSuccess={props.onSuccess}>
                <ResponsiveDialog heading={props.heading} open={props.open} onCancel={props.onCancel}>
                    <DialogContent style={{ backgroundColor: '#f5f5f5', paddingTop: '2em', paddingBottom: '2em' }}>
                        <Container disableGutters>
                            <FormContent languages={languages} onCancel={props.onCancel} />
                        </Container>
                    </DialogContent>

                    <CreateActivityModalActions />
                </ResponsiveDialog>
            </CreateActivityForm>
        )
    }
}

export default CreateActivityModal;